import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { SWRConfig, SWRConfiguration } from 'swr';
import isPropValid from '@emotion/is-prop-valid';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './styles/theme';
import 'normalize.css';
import './styles/fonts.css';
import './styles/custom.css';
import { getJsonAsync } from './services/client';
import { ErrorBoundary } from './components/wrappedComponents';
import { ModalHandler } from './components/modal/Modal';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';
import { AuthenticationProvider } from './providers/AuthenticationProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const swrConfig: SWRConfiguration = {
  revalidateOnFocus: false,
  errorRetryCount: 3,
  fetcher: getJsonAsync
};

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

root.render(
  <ErrorBoundary>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <AuthenticationProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <SWRConfig value={swrConfig}>
              <FeatureFlagProvider>
                <ModalHandler>
                  <App />
                </ModalHandler>
              </FeatureFlagProvider>
            </SWRConfig>
          </ThemeProvider>
        </BrowserRouter>
      </AuthenticationProvider>
    </StyleSheetManager>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
