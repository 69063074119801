import React, {
  useCallback, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { useModalContext } from '../ModalContext';
import { Button, Pane } from '../../wrappedComponents';
import { ProgressState } from '../../../hooks/types';
import {
  OperationInitResult, OperationInstance, useOperation
} from '../../../hooks/useOperation';
import { Message } from '../../messages/Message';

const ProcessingErrorMessage = ({ details }: { details?: string; }) => (
  <Message type="error" large message="An error occurred while processing:" details={details} />
);

interface ProgressProps {
  operation: OperationInstance;
  onInitResult: (operationResult: OperationInitResult) => void;
  onCompleted: (id: string) => void;
}
const Operation = ({ operation, onInitResult, onCompleted }: ProgressProps) => {
  const {
    progress,
    isSubscribed,
    isInitialized,
    initializationFailed,
    isCompleted,
    initializationResult
  } = useOperation(operation);

  useEffect(() => {
    if (initializationResult) onInitResult(initializationResult);
  }, [onInitResult, initializationResult]);

  useEffect(() => {
    if (isCompleted) onCompleted(operation.instanceId);
  }, [isCompleted, operation.instanceId, onCompleted]);

  if (progress) {
    switch (progress.progressState) {
      case ProgressState.Success:
        return <Message type="success" large message={progress.message} />;
      case ProgressState.Loading:
        return <Message type="loading" large message={progress.message} />;
      case ProgressState.Warning:
        return <Message type="warning" large message={progress.message} />;
      case ProgressState.Error:
        return <ProcessingErrorMessage details={progress.message} />;
      default:
        return <ProcessingErrorMessage details={progress.message} />;
    }
  }

  if (initializationFailed) {
    return <ProcessingErrorMessage details="Initialization failed." />;
  }

  if (isInitialized) {
    return <Message type="loading" large message="Waiting for progress..." />;
  }

  if (isSubscribed) {
    return <Message type="loading" large message="Initializing..." />;
  }

  return <Message type="loading" large message="Connecting..." />;
};

const ProgressModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const useCollector = <T, >(count: number, onAllCollected?: (results: T[]) => void) => {
  const [collected, setCollected] = useState<T[]>([]);

  const addItem = useCallback((item: T) =>
    setCollected(prev => [...prev, item]), []);

  const allCollected = collected.length >= count;

  useEffect(() => {
    if (allCollected) onAllCollected?.(collected);
  }, [allCollected, collected, onAllCollected]);

  return { addItem, allCollected };
};

type OperationsModalProps = {
  operations: OperationInstance[];
  onAllInitialized?: (results: OperationInitResult[]) => void;
}
export const OperationsModal = ({ operations, onAllInitialized }: OperationsModalProps) => {
  const { close } = useModalContext();
  const { addItem: addInitResult } = useCollector<OperationInitResult>(operations.length, onAllInitialized);
  const { addItem: addCompleted, allCollected: allCompleted } = useCollector<string>(operations.length);

  return (
    <ProgressModalContainer>
      {operations.map(operation => (
        <Pane key={operation.instanceId} title={operation.title}>
          <Operation
            operation={operation}
            onInitResult={addInitResult}
            onCompleted={addCompleted}
          />
        </Pane>
      ))}
      <Button onClick={close} disabled={!allCompleted}>Close</Button>
    </ProgressModalContainer>
  );
};
