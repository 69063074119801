import React from 'react';
import styled, { css } from 'styled-components';
import {
  NotFound, Stop, OhNo
} from '@instech/icons';
import { ErrorTypes } from './ErrorTypes';

const ErrorImageContainer = styled.div<{errorUnauthorized?: boolean; errorNotAllowed?: boolean;}>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  svg {
    display: block;
    height: auto;
    margin: auto;
    
    @media screen and (max-width: 600px) {
      max-width: 240px;
    }
  }

  /* The Unauthorized error graphic needs to 
  be shifted in order to align it on the page */
  ${props => props.errorUnauthorized && css`
    svg {
      transform: translateX(-68px);
      
      @media screen and (max-width: 600px) {
        transform: translateX(-22px);
      }

      @media screen and (max-width: 320px) {
        transform: translateX(-4px);
      }
    }
  `}

  /* The Not Allowed error graphic needs to be 
  shifted in order to align it on the page */
  ${props => props.errorNotAllowed && css`
    svg {
      transform: translateX(25px);

      @media screen and (max-width: 600px) {
        transform: translateX(11px);
      }
    }
  `}
`;

export const ErrorImage = ({ error }: { error: number;}) => {
  switch (error) {
    case ErrorTypes.unauthorized:
      return (
        <ErrorImageContainer errorUnauthorized>
          <Stop />
        </ErrorImageContainer>
      );
    case ErrorTypes.notFound:
      return (
        <ErrorImageContainer>
          <NotFound />
        </ErrorImageContainer>
      );
    case ErrorTypes.notAllowed:
    default:
      return (
        <ErrorImageContainer errorNotAllowed>
          <OhNo />
        </ErrorImageContainer>
      );
  }
};
