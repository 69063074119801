import { baseTheme } from '@instech/components';

export const theme = {
  ...baseTheme,
  /* Additional style declarations go here */
  table: {
    first: {
      even: '#F1F3F6',
      odd: '#FFFFFF',
      greenEven: '#CBE6DD',
      greenOdd: '#D5EEE3'
    },
    second: {
      even: '#E3E8EE',
      odd: '#F1F3F6',
      greenEven: '#C1DED7',
      greenOdd: '#CBE6DD',
    },
    third: {
      even: '#D4DDE6',
      odd: '#E3E8EE',
      greenEven: '#B7D6D1',
      greenOdd: '#C1DED7'
    },
    empty: {
      even: '#EFDCDA',
      odd: '#FDE7E2',
    }
  },
};

export type Theme = typeof theme;
