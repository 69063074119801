import {
  useCallback, useEffect, useRef, useState
} from 'react';

export const useDebouncedFn = <TArguments extends any[], TResult>(fn: (...args: TArguments) => TResult, delay = 500) => {
  const [latestInvocation, setLatestInvocation] = useState<{ args: TArguments }>();
  const fnRef = useRef(fn);
  fnRef.current = fn;

  const debouncedFn = useCallback((...args: TArguments) => setLatestInvocation({ args }), []);

  useEffect(() => {
    if (!latestInvocation) return () => {};

    const timeout = setTimeout(() => {
      setLatestInvocation(undefined);
      fnRef.current(...latestInvocation.args);
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [latestInvocation, delay]);

  return debouncedFn;
};
