import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Form, Schema } from '@bpmn-io/form-js-viewer';
import '@bpmn-io/form-js-viewer/dist/assets/form-js.css';

export type FormVariables = {
  [x: string]: unknown;
};

export type FormErrors = {
  [x: string]: unknown;
};

export type FormEvent = {
  data: FormVariables;
  errors?: FormErrors;
}

const FormContainer = styled.div`
  .fjs-powered-by {
    display: none;
  }

  .fjs-container {
    --font-family: CalibriWeb, Arial, sans-serif;

    > .fjs-form > .fjs-element > .fjs-vertical-layout {
      padding-inline: 0;
    }

    .fjs-layout-row {
      padding-top: 0;
    }

    .fjs-table-th, .fjs-table-td {
      padding: 0 8px;
      min-width: 0;
      height: 28px;
    }

    h1, h2, h3, h4, h5, p {
      margin-top: 0;
    }
  }
`;

export type CamundaFormViewerProps = {
  className?: string;
  data: FormVariables;
  schema: Schema;
  onFormMounted?: (form: Form) => void;
  onFormChange?: (event: FormEvent) => void;
}

export const CamundaFormViewer = ({ className, data, schema, onFormMounted, onFormChange }: CamundaFormViewerProps) => {
  const formContainerRef = useRef<HTMLDivElement>(null);
  const onFormChangeRef = useRef(onFormChange);
  const onFormMountedRef = useRef(onFormMounted);
  const activeForm = useRef<Form>();

  useEffect(() => {
    if (!schema) return () => {};

    const form = new Form({
      container: formContainerRef.current
    });

    activeForm.current = form;
    onFormMountedRef.current?.(form);

    void form.importSchema(schema, data);

    form.on('changed', 500, (event: FormEvent) => onFormChangeRef.current?.(event));

    return () => {
      activeForm.current = undefined;
      form.destroy();
    };
  }, [schema, data]);

  return <FormContainer className={className} ref={formContainerRef} />;
};
