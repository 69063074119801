import React, { PropsWithChildren } from 'react';
import { Route, Routes } from 'react-router';
import { UnauthorizedPage } from '../pages/error/UnauthorizedPage';
import { RequireAuth } from './ProtectedRoute';

export const RootRouting = ({ children } :PropsWithChildren) => (
  <Routes>
    <Route path="/unauthorized" element={<UnauthorizedPage />} />
    <Route path="*" element={<RequireAuth>{children}</RequireAuth>} />
  </Routes>
);
