import { useCallback } from 'react';
import { useModalContext } from './ModalContext';
import { OperationsModal } from './OperationsModal/OperationsModal';
import { OperationInitResult, OperationInstance } from '../../hooks/useOperation';

export const useOperationsModal = () => {
  const { open, close } = useModalContext();

  const startOperations = useCallback(async (title: string, operations: OperationInstance[]) => {
    const operationInitResults = await new Promise<OperationInitResult[]>((resolve, reject) => {
      open({
        component: OperationsModal,
        options: { title, size: 'medium' },
        args: {
          operations,
          onAllInitialized: resolve
        }
      });
    });
    return {
      startResponses: operationInitResults.map(x => x.response),
      allStartedOk: operationInitResults.every(x => x.success)
    };
  }, [open]);

  return { startOperations, close };
};
