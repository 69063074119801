import React from 'react';
import styled from 'styled-components';
import { Divider } from '@instech/components';
import { Pane } from '../wrappedComponents';

const NoSelectionHeading = styled.h1`
  font-size: 24px;
  margin-top: 0;
`;

export const NoSelectedUserTaskPage = () => (
  <Pane>
    <NoSelectionHeading>No selection</NoSelectionHeading>
    <Divider />
    Selected tasks from the left hand list will appear here.
  </Pane>
);
