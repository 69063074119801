import React, { FC, HTMLProps } from 'react';
import {
  formatNumber, formatWithDigitsOrEmpty, truncateDecimals
} from '../../utils/number';
import { TableCellMaxContent } from './TableCellMaxContent';

const formatOrEmpty = (number?: number | null, showZero?: boolean) => {
  if (showZero && number === 0) return formatNumber(number);
  return number ? formatNumber(truncateDecimals(number, 0)) : '-';
};

interface Props extends Omit<HTMLProps<HTMLSpanElement>, 'value'> {
  value: number | null;
  tooltipId: string;
}
export const TableCellDigitsTooltip: FC<Props> = ({ value, tooltipId, ...props }) => (
  <TableCellMaxContent {...props}>
    <span data-tooltip-id={tooltipId} data-tip={formatWithDigitsOrEmpty(value, true)}>
      {formatOrEmpty(value, true)}
    </span>
  </TableCellMaxContent>
);
