import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Header } from '../Header';
import { ContentArea } from './ContentArea';

const PageWrapper = styled.div`
  background: ${props => props.theme.whiteBlue};
  min-height: 100vh;
`;

export const Page = ({ children } : PropsWithChildren) => (
  <PageWrapper>
    <Header />
    <ContentArea>
      {children}
    </ContentArea>
  </PageWrapper>
);
