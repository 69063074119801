import { useQueryParams } from './useQueryParams';

const taskQueryFilterKey = ['umr', 'ucr', 'closingRef', 'assignee', 'state'] as const;

export type TaskQueryFilters = typeof taskQueryFilterKey[number];

export const useTasksQueryParams = () => {
  const { setParams, setParam, params } = useQueryParams(taskQueryFilterKey);
  return {
    setFilter: setParam,
    resetFilters: setParams,
    filters: params
  };
};
