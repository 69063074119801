import React, {
  FormEvent, useCallback, useEffect, useRef, useState
} from 'react';
import { TextFieldControlled, TextFieldControlledProps } from '@instech/components';
import { useDebouncedFn } from '../../hooks/useDebouncedFn';

type DebouncedTextFieldControlledProps = Omit<TextFieldControlledProps, 'onChange'> & { onValueChange: (newValue: string) => void };
export const DebouncedTextFieldControlled = ({ onValueChange, value, ...rest }: DebouncedTextFieldControlledProps) => {
  const [internalValue, setInternalValue] = useState(value);

  const lastExpectedExternalValue = useRef(value);
  const internalValueRef = useRef(internalValue);
  internalValueRef.current = internalValue;

  const propagateValueChange = useCallback((newValue: string) => {
    onValueChange(newValue);
  }, [onValueChange]);

  const debouncedPropagateValueChange = useDebouncedFn(propagateValueChange);

  const handleValueChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setInternalValue(newValue);
    debouncedPropagateValueChange(newValue);
  }, [debouncedPropagateValueChange]);

  // Sync external value -> internal value when changed outside
  useEffect(() => {
    if (value === internalValueRef.current || value === lastExpectedExternalValue.current) return;
    setInternalValue(value);
    lastExpectedExternalValue.current = value;
  }, [value]);

  return <TextFieldControlled value={internalValue} onChange={handleValueChange} {...rest} />;
};
