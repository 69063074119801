import React, {
  ComponentProps, useCallback, useState
} from 'react';
import styled from 'styled-components';
import { Loader } from '@instech/components';
import { BoxButton, Button } from '../wrappedComponents';
import { useIsMounted } from '../../hooks/useIsMounted';

export const ButtonLoader = styled(Loader)<{ inverted?: boolean }>`
  color: ${({ inverted, theme }) => inverted ? theme.blue : theme.white};
  height: 18px;
`;

type SubmitButtonProps = Omit<ComponentProps<typeof BoxButton>, 'onClick'> & {
  onSubmit: (event?: any) => Promise<any> | void;
}

const useSubmitOnClick = (onSubmit: (event?: any) => Promise<any> | void) => {
  const isMounted = useIsMounted();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = useCallback(async (event?: any) => {
    setIsSubmitting(true);
    try {
      await onSubmit(event);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    if (isMounted.current) setIsSubmitting(false);
  }, [isMounted, onSubmit]);

  return { isSubmitting, handleClick };
};

export const SubmitButton = ({ onSubmit, inverted, children, disabled, ...props }: SubmitButtonProps) => {
  const { isSubmitting, handleClick } = useSubmitOnClick(onSubmit);

  return (
    <BoxButton {...props} inverted={inverted} disabled={disabled || isSubmitting} onClick={handleClick}>
      { isSubmitting ? <ButtonLoader inverted={inverted} /> : children }
    </BoxButton>
  );
};

type SlimSubmitButtonProps = Omit<ComponentProps<typeof Button>, 'onClick'> & {
  onSubmit: (event?: any) => Promise<any> | void;
}

export const SlimSubmitButton = ({ onSubmit, children, disabled, ...props }: SlimSubmitButtonProps) => {
  const { isSubmitting, handleClick } = useSubmitOnClick(onSubmit);

  return (
    <Button {...props} disabled={disabled || isSubmitting} onClick={handleClick}>
      { isSubmitting ? <ButtonLoader /> : children }
    </Button>
  );
};
