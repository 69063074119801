import {
  HubConnectionBuilder, IHttpConnectionOptions, LogLevel
} from '@microsoft/signalr';
import {
  useEffect, useRef, useState
} from 'react';
import { SignalREvent } from './types';
import { useIsMounted } from './useIsMounted';

const connectionOptions: IHttpConnectionOptions = {
  accessTokenFactory: undefined,
};

const buildConnection = () =>
  new HubConnectionBuilder()
    .withUrl('/hubs/notifications', connectionOptions)
    .configureLogging(LogLevel.Error)
    .build();

export const useSignalR = <T, >(handleNotification: (notification: SignalREvent<T>) => void, topic?: string) => {
  const isMounted = useIsMounted();
  const [subscribedTopic, setSubscribedTopic] = useState<string>();
  const handleNotificationRef = useRef(handleNotification);
  const topicRef = useRef(topic);

  useEffect(() => {
    if (!topic) return () => {};

    const connection = buildConnection();

    void connection.start().then(() => {
      void connection!.send('subscribe', topic).then(() => isMounted.current && setSubscribedTopic(topic));
      connection!.on('Notify', e => {
        if (topic === topicRef.current) handleNotificationRef.current(e);
      });
    });

    return () => connection.stop();
  }, [isMounted, topic]);

  return {
    isSubscribed: subscribedTopic === topic
  };
};
