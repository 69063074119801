import { format } from 'date-fns';

// Converts date only part of date to Date object in UTC
const toUtcDateOnly = (date: Date) => new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

// Convert Date to string: "YYYY-MM-DDTHH:mm:ss.offset" with UTC offset
export const getDateISOString = (date: Date) => toUtcDateOnly(date).toISOString();

// Convert Date string to "YYYY-MM-DD" (C# DateOnly equivalent)
// - ensure UTC first to avoid date shift across midnight from .toISOString()
export const getDateOnlyString = (dateString: string) => toUtcDateOnly(new Date(dateString)).toISOString().split('T')[0];

export const asDate = (date: string | Date) => typeof date === 'string' ? new Date(date) : date;

// Convert Date or date string (ISO8601) to long form display date like 27 Oct 2022 - 14:34
// - ensure UTC first to avoid date shift across midnight from .toISOString()
export const toLongDateTime = (date: string | Date) => format(asDate(date), 'd MMM yyyy - HH:mm');
