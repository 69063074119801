import { TaskQueryFilters } from '../hooks/useTasksQueryParams';

type VariableFilterConfig = {
  variableName: string;
  buttonLabel: (value: string) => string;
  inputLabel: string;
  queryKey: TaskQueryFilters;
}

export const variableFilters: VariableFilterConfig[] = [
  {
    variableName: 'uniqueMarketReference',
    buttonLabel: value => `Unique Market Reference: ${value}`,
    inputLabel: 'Unique Market Reference',
    queryKey: 'umr'
  },
  {
    variableName: 'closingReferenceValue',
    buttonLabel: value => `Closing Reference: ${value}`,
    inputLabel: 'Closing Reference',
    queryKey: 'closingRef'
  },
  {
    variableName: 'uniqueClaimReference',
    buttonLabel: value => `Unique Claim Reference: ${value}`,
    inputLabel: 'Unique Claim Reference',
    queryKey: 'ucr'
  }
];
