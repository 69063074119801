import React, { FC, HTMLProps } from 'react';
import { StyledTextCell } from './Components';
import { ellipsisOrDash, textOrNone } from '../../utils/text';

interface Props extends Omit<HTMLProps<HTMLSpanElement>, 'value'> {
  value: string | null;
  tooltipId: string;
  underline?: boolean;
  maxContent?: boolean;
}
export const TableCellTextTooltip: FC<Props> = ({ value, tooltipId, ...props }) => (
  <StyledTextCell {...props}>
    <span data-tooltip-id={tooltipId} data-tip={textOrNone(value)}>
      {ellipsisOrDash(value)}
    </span>
  </StyledTextCell>
);
