import useSWR from 'swr';
import { LabelValuePair } from '@instech/components';
import { postJsonAsync } from './client';

export interface InsuranceObject {
  id: string;
  insuranceYear: number;
  objectNumber: number;
  imoNumber: number;
  name: string;
  flag: LabelValuePair | null;
  class: LabelValuePair | null;
  insurancePeriodFrom: string;
  insurancePeriodTo: string;
  fleetName: string;
  contractPeriodFrom: string | null;
  contractPeriodTo: string | null;
}

export interface InsuranceObjectRequest {
  imoNumber: number | '';
  name?: string;
  effectiveDate: string; // YYYY-MM-DD
}

export const useObjects = (object?: InsuranceObjectRequest) => {
  const params = new URLSearchParams();

  if (object?.imoNumber) {
    params.append('imoNumber', object.imoNumber.toString());
  }
  if (object?.name) {
    params.append('objectName', object.name);
  }
  if (object?.effectiveDate) {
    params.append('effectiveDate', object.effectiveDate);
  }

  const url = object && `objects?${params}`;

  return useSWR<InsuranceObject[]>(url);
};

export interface RenameObjectRequest {
  insuranceYear: number;
  objectNumber: number;
  newName: string;
  oldName: string;
  effectiveDate: string; // YYYY-MM-DD
  fleetName: string;
  contractPeriodFrom: string | null;
  contractPeriodTo: string | null;
  confirmationEmailRecipients: string[];
}

export const renameObjectAsync = async (request: RenameObjectRequest) => {
  const url = 'objects/rename';
  return postJsonAsync(url, request);
};

export interface ChangeObjectFlagRequest {
  insuranceYear: number;
  objectNumber: number;
  newFlag: string;
  oldFlag: string | null;
  effectiveDate: string; // YYYY-MM-DD
  fleetName: string;
  contractPeriodFrom: string | null;
  contractPeriodTo: string | null;
  confirmationEmailRecipients: string[];
}

export const changeObjectFlagAsync = async (request: ChangeObjectFlagRequest) => {
  const url = 'objects/change-flag';
  return postJsonAsync(url, request);
};

export interface ChangeObjectClassRequest {
  insuranceYear: number;
  objectNumber: number;
  newClass: string;
  oldClass: string | null;
  effectiveDate: string; // YYYY-MM-DD
  fleetName: string;
  contractPeriodFrom: string | null;
  contractPeriodTo: string | null;
  confirmationEmailRecipients: string[];
}

export const changeObjectClassAsync = async (request: ChangeObjectClassRequest) => {
  const url = 'objects/change-class';
  return postJsonAsync(url, request);
};
