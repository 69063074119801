import {
  useCallback, useEffect, useRef, useState
} from 'react';
import { AxiosResponse } from 'axios';
import { useSignalR } from './useSignalR';
import { ProgressState, SignalREvent } from './types';
import { useIsMounted } from './useIsMounted';

export interface OperationInstance {
  instanceId: string;
  title: string;
  progressTopic: string;
  /**
   * Operation start request that will be called when opening the modal if given.
   * Must return a Promise<AxiosResponse | null | undefined> which will be translated to progress instance initialization status
   */
  startRequest: () => Promise<AxiosResponse | null | undefined>;
}

export type OperationInitResult = {
  instanceId: string;
  success: boolean;
  response: AxiosResponse | null | undefined;
}

const isOkResponse = (response: AxiosResponse | null | undefined) => !!response && response.status >= 200 && response.status < 300;

const isCompletedState = (progressState: ProgressState) =>
  progressState === ProgressState.Success ||
  progressState === ProgressState.Error ||
  progressState === ProgressState.Warning;

interface NotificationData {
  message?: string;
  progressState: ProgressState;
}

type Notification = SignalREvent<NotificationData>;

const selectLatestNotification = (incoming: Notification, previous?: Notification) => {
  if (new Date(incoming.timestamp) > new Date(previous?.timestamp ?? 0)) {
    return incoming;
  }
  return previous;
};

export const useOperation = ({ instanceId, progressTopic, startRequest }: OperationInstance) => {
  const isMounted = useIsMounted();
  const startedOperationId = useRef<string>();
  const [initializationResult, setInitializationResult] = useState<OperationInitResult>();
  const [latestNotification, setLatestNotification] = useState<Notification | undefined>(undefined);

  const progress = latestNotification?.data;

  const isInitialized = !!initializationResult?.success;
  const initializationFailed = !!initializationResult && !initializationResult.success;
  const hasFinalProgress = !!latestNotification && isCompletedState(latestNotification.data.progressState);

  const isCompleted = initializationFailed || hasFinalProgress;

  const handleNotification = useCallback((event: Notification) => {
    // Only set message if it is newer than the last message. Message order is not guaranteed.
    setLatestNotification(previous => selectLatestNotification(event, previous));
  }, []);

  const { isSubscribed } = useSignalR(handleNotification, progressTopic);

  useEffect(() => {
    if (!isSubscribed || startedOperationId.current === instanceId) return;

    startedOperationId.current = instanceId;

    void startRequest()
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error('Failed to start operation due to unexpected error: ', e);
        return undefined;
      })
      .then(response => {
        if (!isMounted.current) return;

        setInitializationResult({
          instanceId,
          response,
          success: isOkResponse(response)
        });
      });
  }, [instanceId, isSubscribed, startRequest, isMounted]);

  return {
    progress,
    initializationResult,
    initializationFailed,
    isSubscribed,
    isInitialized,
    isCompleted,
  };
};
