import React, {
  PropsWithChildren, useEffect, useState
} from 'react';
import { PageLoader } from '@instech/components';
import { useAccount } from '../../providers/AuthenticationProvider';
import { NoAccessPage } from '../pages/authentication/NoAccessPage';
import { useRoles } from '../../services/rolesServices';

const AuthenticationHandler = ({ children }: PropsWithChildren) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { isAuthenticated, login } = useAccount();

  useEffect(() => {
    if (!isAuthenticated) {
      setIsLoggingIn(true);
      login();
    }
  }, [isAuthenticated, login]);

  if (isLoggingIn) return <div>You will now be logged in!</div>;

  return <>{children}</>;
};

const AuthorizationHandler = ({ children }: PropsWithChildren) => {
  const { data: roles, isLoading } = useRoles();

  if (isLoading) return <PageLoader />;
  if (roles?.length === 0) return <NoAccessPage />;
  return <>{children}</>;
};

export const RequireAuth = ({ children } : PropsWithChildren) => (
  <AuthenticationHandler>
    <AuthorizationHandler>
      {children}
    </AuthorizationHandler>
  </AuthenticationHandler>
);
