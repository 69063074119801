export const routes = {
  home: '/',
  renameVessel: '/rename-vessel',
  changeFlag: '/change-flag',
  changeClass: '/change-class',
  tasklist: {
    base: '/user-tasks',
    userTasks: '',
    userTask: ':userTaskId'
  }
};
