import React from 'react';
import styled from 'styled-components';
import { ErrorView, ErrorViewContent } from './core/ErrorView';
import { ErrorTypes } from './core/ErrorTypes';
import { BoxButton } from '../../wrappedComponents';
import { useAccount } from '../../../providers/AuthenticationProvider';

const errorMessage = `
  The request has not been applied because it lacks valid
  authentication credentials for the target resource.
`;

const Container = styled.div`
  max-width: 412px;
  margin: 0px auto;
  padding: 64px 8px 80px;
`;

const LogoutContent = ({ logout }: { logout: () => void }) => (
  <Container>
    <BoxButton width="100%" onClick={logout}>Log Out</BoxButton>
  </Container>
);

export const UnauthorizedPage = ({ reset }: { reset?: () => void }) => {
  const { logout, isAuthenticated } = useAccount();
  return (
    <ErrorView
      type={ErrorTypes.unauthorized}
      title="Unauthorized"
      message={errorMessage}
    >
      {isAuthenticated ? <LogoutContent logout={logout} /> : <ErrorViewContent reset={reset ?? (() => {})} /> }
    </ErrorView>
  );
};
