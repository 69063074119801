import React, { ComponentProps, ReactNode } from 'react';
import styled from 'styled-components';
import { Pane } from '../wrappedComponents';

const PaneWrapper = styled.div<{ fitParent?: boolean; }>`
  ${({ fitParent }) => fitParent && `
    > * {
      height: 100%;

      > * {
        height: 100%;
        box-sizing: border-box;
      }
    }
  `}
`;

const PaneContents = styled.div<{ fitParent?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ fitParent }) => fitParent && `
    height: 100%;
  `}
`;

const MainContents = styled.div<{ fitParent?: boolean }>`
  min-height: 0;
  flex: 1 1 auto;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
`;

const FixedSizeContent = styled.div`
  flex: 0 0 auto;
  padding: 32px;
`;

const FlexibleContent = styled.div<{ fitParent?: boolean }>`
  padding: 16px 32px 32px;
  ${({ fitParent }) => fitParent && `
    min-height: 0;
    flex: 1 1 auto;
    overflow-x: auto;
  `}
`;

const AsideContents = styled(FlexibleContent)`
  box-sizing: border-box;
  background: ${({ theme }) => theme.lightBlue};
  flex: 0 0 auto;

  ${({ fitParent }) => fitParent && `
    height: 100%;
  `}
`;

const HeaderContent = styled(FixedSizeContent)`
  padding: 32px 32px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.whiteBlue};
`;

const FooterContent = styled(FixedSizeContent)`
  padding: 16px 32px 32px;
  border-top: 1px solid ${({ theme }) => theme.whiteBlue};;
`;

type ContentPaneProps = ComponentProps<typeof Pane> & {
  header?: ReactNode;
  footer?: ReactNode;
  aside?: ReactNode;
  fitParent?: boolean;
  className?: string;
};

/**
 * Wrapper component around component libs Pane that can have content fit to match parent,
 * with optional fixed size header and footer,
 * and content in between that will grow to fill with scroll if needed
 */
export const ContentPane = ({ header, children, footer, aside, fitParent, className, padding = '0px', ...restProps }: ContentPaneProps) => (
  <PaneWrapper fitParent={fitParent} className={className}>
    <Pane padding={padding} {...restProps}>
      <PaneContents fitParent={fitParent}>
        {header && (
          <HeaderContent>
            {header}
          </HeaderContent>
        )}
        <MainContents fitParent={fitParent}>
          <FlexibleContent fitParent={fitParent}>
            {children}
          </FlexibleContent>
          {aside && (
            <AsideContents fitParent={fitParent}>
              {aside}
            </AsideContents>
          )}
        </MainContents>
        {footer && (
          <FooterContent>
            {footer}
          </FooterContent>
        )}
      </PaneContents>
    </Pane>
  </PaneWrapper>
);
