import { NumberParser } from '@internationalized/number';

/**
 * Assert whether provided value is a Number value. Returns true
 * if provided value can be parsed as numerical value (including strings).
 */
export const isRealNumber = (value: any) => (
  !Number.isNaN(parseFloat(value)) && !Number.isNaN(value - 0) && typeof value !== 'boolean'
);

export const formatNumber = (value: number) => value.toLocaleString('en');

export const formatNumberWithTwoDecimals = (value: number) => value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

/**
 * Truncates a number's decimals if it has more decimals than the given maxDecimals.
 * This is not a rounding, but instead a hard cut-off at the given decimal place
 * (ex. 34.5678 to 34.56, rather than 34.57)
 */
export const truncateDecimals = (value: number, maxDecimals: number = 0): number => {
  // If val has no decimals, can just return, no need to proceed
  if (!(Number(value) % 1)) return value;

  const [number, decimals] = value.toString().split('.');
  const trimDecimals = decimals.substring(0, maxDecimals);

  if (!trimDecimals) return Number(number);
  return Number(`${number}.${trimDecimals}`);
};

export const formatWithDigitsOrEmpty = (number?: string | number | null, showZero?: boolean, multFactor?: number | null) => {
  const convertedNumber = Number(number);

  if (showZero && number === 0) return formatNumberWithTwoDecimals(number);
  return !Number.isNaN(convertedNumber) && convertedNumber !== 0 ? formatNumberWithTwoDecimals(convertedNumber * (multFactor ?? 1)) : '';
};

export const sanitizeFormattedNumber = (value: string) => value.replace(/\s/g, '');

export const testValidNumberWithGivenDecimals = (value: number | string, decimals: number) => {
  /* eslint-disable */
  const pattern = new RegExp('^(-)?\\d*(\\.\\d{0,' + decimals + '})?$');
  return pattern.test(String(value));
};

export const getParsedDecimalAsStringWithGivenPrecision = (value: string, precision: number = 2, skipThousandSeparatorTest: boolean = false): string => {
  const englishNumberParser = new NumberParser('en-US', { style: 'decimal' });
  const nonEnglishNumberParser = new NumberParser('da-DK', { style: 'decimal' });

  const norwegianDecimalOnly = /^-?\d+(,\d+)?$/;
  const norwegianDotAsThousand = /^-?\d+(\.\d{3})+(,\d+)?$/;
  const norwegianSpaceAsThousand = /^-?\d+( \d{3})+(,\d+)?$/;

  const englishDecimalOnly = /^-?\d+(\.\d+)?$/;
  const englishCommaAsThousand = /^-?\d+(,\d{3})+(\.\d+)?$/;
  const englishSpaceAsThousand = /^-?\d+( \d{3})+(\.\d+)?$/;

  // system-calculated numbers use dot as decimal separator, so for small calculated numbers with 3 decimals we need to skip the thousand separator test
  if (norwegianDecimalOnly.test(value) || (!skipThousandSeparatorTest && norwegianDotAsThousand.test(value)) || norwegianSpaceAsThousand.test(value)) {
    const trimmedValue = value.replace(/[^0-9.,-]+/g, '');
    const decimalValue = nonEnglishNumberParser.parse(trimmedValue);
    return truncateDecimals(Number(decimalValue), precision).toString();
  }

  if (englishDecimalOnly.test(value) || englishCommaAsThousand.test(value) || englishSpaceAsThousand.test(value)) {
    const trimmedValue = value.replace(/[^0-9.,-]+/g, '');
    const decimalValue = englishNumberParser.parse(trimmedValue);
    return truncateDecimals(Number(decimalValue), precision).toString();
  }

  return sanitizeFormattedNumber(value);
};
