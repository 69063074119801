/**
 * Split an array into [A, B], based on a provided conditional callback.
 * Useful as a shorthand function for partitioning an array based on a
 * known set of parameters. The callback must always return a boolean.
 */
export const arrayPartition = <T = any>(inputArray: T[], isValid: (value: T) => boolean): [T[], T[]] => {
  const [pass, fail]: [T[], T[]] = [[], []];
  inputArray.forEach(item => isValid(item) ? pass.push(item) : fail.push(item));
  return [pass, fail];
};
