import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';
import { Filter } from '@instech/icons';
import { CamundaTask, TaskContextAction } from '../../camunda/CamundaTask';
import { routes } from '../routes';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { useTasksQueryParams } from '../../../hooks/useTasksQueryParams';
import { FormVariables } from '../../camunda/CamundaFormViewer';
import { variableFilters } from '../../../utils/userTasks';

const getStringVariableOrNull = (variableName: string, variables: FormVariables) => {
  const value = variables[variableName];
  return value && typeof value === 'string' ? value : null;
};

const StyledFilterIcon = styled(Filter)`
  height: 18px;
`;

export const UserTaskPage = () => {
  const { setFilter } = useTasksQueryParams();
  const location = useLocation();
  const isMounted = useIsMounted();
  const params = useParams();
  const navigate = useNavigate();

  const handleCompletedTask = useCallback(() => {
    if (isMounted) navigate({ pathname: routes.tasklist.base, search: location.search });
  }, [isMounted, location.search, navigate]);

  const setListFilterContextActions = useMemo<TaskContextAction[]>(() => variableFilters.map<TaskContextAction>(filter => ({
    label: variables => filter.buttonLabel(getStringVariableOrNull(filter.variableName, variables) || ''),
    onClick: variables => setFilter(filter.queryKey, getStringVariableOrNull(filter.variableName, variables)),
    condition: variables => typeof variables[filter.variableName] === 'string',
    startIcon: <StyledFilterIcon />
  })), [setFilter]);

  return (
    <CamundaTask
      fitParent
      taskId={params.userTaskId || ''}
      contextActions={setListFilterContextActions}
      onComplete={handleCompletedTask}
    />
  );
};
