import React, {
  useCallback, useMemo, useState
} from 'react';
import styled from 'styled-components';
import {
  DangerNotification, InformationNotification, TextField
} from '@instech/components';
import {
  Formik, FormikErrors, FormikHelpers
} from 'formik';
import {
  Edit, Trash, Close
} from '@instech/icons';
import {
  UserTaskComment, UserTaskDetails, addTaskComment,
  editTaskComment,
  removeTaskComment
} from '../../services/userTasksService';
import { toLongDateTime } from '../../utils/date';
import { ButtonLoader } from '../buttons/SubmitButton';
import { Button, LinkButton } from '../wrappedComponents';
import { extractErrorInfo } from '../../utils/errors';
import { useIsMounted } from '../../hooks/useIsMounted';
import { useConfirmModal } from '../modal/useConfirmModal';
import { useAccount } from '../../providers/AuthenticationProvider';

const TaskCommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CommentList = styled.ul`
  list-style: none;
  flex: 1 1 auto;
  min-height: 0;
  padding-left: 0;
  margin-top: 0;
  overflow-x: auto;
`;

const CommentWrapper = styled.li`
  margin-bottom: 16px;
  max-width: 300px;

  > *:first-child {
    display: flex;
    justify-content: space-between;
    font-style: italic;
    margin-bottom: 4px;
    padding-right: 8px;
    color: #003C71;
  }
`;

const CommentDate = styled.div`
  font-size: 0.8rem;
`;

const CommentUser = styled.div`
  font-weight: bold;
`;

const CommentFormContents = styled.div`
  min-height: auto;
  flex: 0 0 auto;
`;

const CommentFormRow = styled.form<{ hasLabel: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: ${({ hasLabel }) => hasLabel ? 'center' : 'flex-start'};
`;

const newCommentInitialValues = { contents: '' };

type CommentFormValues = { contents: string };
const validateNewCommentForm = (values: CommentFormValues) => {
  const errors: FormikErrors<CommentFormValues> = {};

  if (!values.contents) {
    errors.contents = "Comment can't be empty";
  }

  return errors;
};

type CommentEditorProps = {
  comment?: UserTaskComment;
  inputLabel?: string;
  saveLabel: string;
  onSave: (data: CommentFormValues) => Promise<unknown>;
  disabled?: boolean;
}
const CommentEditor = ({ comment, inputLabel, saveLabel, disabled, onSave }: CommentEditorProps) => {
  const isMounted = useIsMounted();

  const [saveCommentError, setSaveCommentError] = useState<string>();

  const initialValues = useMemo(() => comment ? { contents: comment.contents } : newCommentInitialValues, [comment]);

  const handleFormSubmit = useCallback(async (data: CommentFormValues, helpers: FormikHelpers<CommentFormValues>) => {
    setSaveCommentError(undefined);
    try {
      await onSave(data);
      helpers.resetForm();
    } catch (e) {
      if (isMounted.current) setSaveCommentError(extractErrorInfo(e).message);
    }
  }, [isMounted, onSave]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateNewCommentForm}
    >
      {({ handleSubmit, isSubmitting }) => (
        <CommentFormContents>
          <CommentFormRow hasLabel={!!inputLabel}>
            <TextField label={inputLabel || ''} noLabel={!inputLabel} name="contents" placeholder="Enter your comment" disabled={disabled} />
            <Button disabled={disabled} type="submit" onClick={handleSubmit}>
              { isSubmitting ? <ButtonLoader inverted /> : saveLabel }
            </Button>
          </CommentFormRow>
          {saveCommentError && (
            <DangerNotification
              headingText={saveCommentError}
              size="small"
            />
          )}
          {disabled && (
            <InformationNotification headingText="Comments can only be added to open tasks" size="small" />
          )}
        </CommentFormContents>
      )}
    </Formik>
  );
};

type CamundaTaskCommentProps = {
  task: UserTaskDetails;
  comment: UserTaskComment;
  onCommentChanged?: () => Promise<any> | void;
}
const Comment = ({ task, comment, onCommentChanged }: CamundaTaskCommentProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAccount();

  const { requestConfirmation } = useConfirmModal();

  const handleUpdateComment = useCallback(async (data: CommentFormValues) => {
    await editTaskComment(task.id, comment.id, data);
    setIsEditing(false);
    await onCommentChanged?.();
  }, [task.id, comment.id, onCommentChanged]);

  const handleDeleteComment = useCallback(async () => {
    const confirmed = await requestConfirmation({
      title: 'Confirm deletion',
      description: 'Are you sure you want to delete this comment?',
      confirmText: 'Yes, delete comment'
    });
    if (confirmed) {
      await removeTaskComment(task.id, comment.id);
      await onCommentChanged?.();
    }
  }, [requestConfirmation, task.id, comment.id, onCommentChanged]);

  const startEditing = useCallback(() => setIsEditing(true), []);
  const stopEditing = useCallback(() => setIsEditing(false), []);

  const isByCurrentUser = user?.email === comment.user.email;
  const isTaskOpen = task.taskState === 'Created';
  const canEdit = isByCurrentUser && isTaskOpen;

  return (
    <CommentWrapper>
      <div>
        <div>
          <CommentUser>{comment.user.name ?? comment.user.email}</CommentUser>
          <CommentDate>{toLongDateTime(comment.creationDate)}</CommentDate>
          {comment.updateDate && (
            <CommentDate>
              Updated:
              {toLongDateTime(comment.updateDate)}
            </CommentDate>
          )}
        </div>
        {canEdit && (
          <div>
            {isEditing ? (
              <LinkButton onClick={stopEditing} startIcon={<Close width={18} height={18} />}>Cancel</LinkButton>
            ) : (
              <>
                <LinkButton onClick={startEditing} title="Edit" startIcon={<Edit width={18} height={18} />} />
                <LinkButton onClick={handleDeleteComment} title="Delete" startIcon={<Trash width={18} height={18} />} />
              </>
            )}
          </div>
        )}
      </div>
      {isEditing ? (
        <CommentEditor saveLabel="Save" comment={comment} onSave={handleUpdateComment} />
      ) : (
        <div>{comment.contents}</div>
      )}
    </CommentWrapper>
  );
};

type CamundaTaskCommentsProps = {
  task: UserTaskDetails,
  onCommentsChanged?: () => Promise<any> | void;
}
export const CamundaTaskComments = ({ task, onCommentsChanged }: CamundaTaskCommentsProps) => {
  const canAddComment = task.taskState === 'Created';

  const handleAddNewComment = useCallback(async (data: CommentFormValues) => {
    await addTaskComment(task.id, data);
    await onCommentsChanged?.();
  }, [task.id, onCommentsChanged]);

  return (
    <TaskCommentsWrapper>
      <CommentList>
        {task.comments.map(c => (
          <Comment
            key={c.id}
            task={task}
            onCommentChanged={onCommentsChanged}
            comment={c}
          />
        ))}
      </CommentList>
      <CommentEditor inputLabel="New comment" saveLabel="Add" onSave={handleAddNewComment} disabled={!canAddComment} />
    </TaskCommentsWrapper>
  );
};
