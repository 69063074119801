import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BoxButton } from './wrappedComponents';
import { routes } from './pages/routes';
import { useAccount } from '../providers/AuthenticationProvider';

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2em;
  background: ${props => props.theme.lightBlue};
  padding: 1em;
  border-bottom: 1px solid ${props => props.theme.lightBlue};
`;

const AccountInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:visited, &:hover, &:focus {
    color: inherit;
  }
`;

export const Header = () => {
  const { user, isAuthenticated, logout } = useAccount();
  return (
    <HeaderWrapper>
      <h1><StyledLink to={routes.home}>Etuity TaskFlow</StyledLink></h1>
      <AccountInfo>
        <p>
          {isAuthenticated ? `Signed in as: ${user?.name} (${user?.email})` : 'Not logged in'}
        </p>
        <BoxButton onClick={logout}>Sign out</BoxButton>
      </AccountInfo>
    </HeaderWrapper>
  );
};
