import React, { ChangeEvent } from 'react';
import { TextField, TextFieldProps } from '@instech/components';
import { useField } from 'formik';

export const UpperCaseTextField = ({ name, ...fieldProps }: TextFieldProps) => {
  const [,, helpers] = useField(name);
  const transforToUpper = ({ target: { value } } : ChangeEvent<HTMLInputElement>) => {
    void helpers.setValue(value.toUpperCase());
  };

  return <TextField name={name} onChange={transforToUpper} {...fieldProps} />;
};
