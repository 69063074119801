import { useCallback, useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { addQueryParams, apiClient } from './client';

export type TaskState = 'Unknown' | 'Created' | 'Completed' | 'Canceled' | 'Failed';

export type UserTasksQuery = {
  sort?: string[];
  assigned?: boolean;
  assignees?: string[];
  state?: TaskState;
  pageSize?: number;
  taskVariables?: { [name: string]: string };
  searchAfter?: string[];
  searchAfterOrEqual?: string[];
  searchBefore?: string[];
  searchBeforeOrEqual?: string[];
}

export type AssigneeType = 'User' | 'Group';

export type AssigneeInfo = {
  AssigneeType: AssigneeType;
  assignee: string;
  name?: string;
}

export type UserTask = {
  id: string;
  name?: string | null;
  taskDefinitionId?: string | null;
  processName?: string | null;
  creationDate?: string | null;
  completionDate?: string | null;
  assigneeInfo?: AssigneeInfo | null;
  taskState: TaskState;
  formKey: string;
  formId?: string | null;
  formVersion?: number | null;
  isFormEmbedded?: boolean | null;
  processDefinitionKey: string;
  processInstanceKey: string;
  tenantId: string;
  dueDate?: string | null;
  followUpDate?: string | null;
  sortValues: string[];
}

export type ItemsPage<TItem, TQuery> = {
  currentQuery: TQuery;
  items: TItem[];
  nextPageQuery: TQuery | null;
  prevPageQuery: TQuery | null;
};

export type TasklistUser = {
  subjectId: string;
  name: string;
  email: string;
};

export type UserTaskVariable = {
  id: string;
  name: string;
  value: string | null;
  draftValue: string | null;
}

export type UserTaskVariableInput = {
  name: string;
  value: string;
}

export type UserTaskComment = {
  id: string;
  contents: string;
  creationDate: string;
  updateDate?: string | null;
  user: TasklistUser;
}

export type UserTaskCommentInput = Pick<UserTaskComment, 'contents'>;

export type CompleteUserTaskRequest = {
  variables: UserTaskVariableInput[]
};

export type SetUserTaskAssigneeRequest = {
  assignee: string;
}

export type UserTaskDetails = UserTask & {
  variables: UserTaskVariable[];
  comments: UserTaskComment[];
}

export type UserTasksPage = ItemsPage<UserTask, UserTasksQuery>;

export type TasklistGroupUser = {
  id: string;
  name: string;
};

export const getAssigneeFromUser = (user: TasklistUser | TasklistGroupUser) => {
  if ('email' in user) return user.email;
  return user.id;
};

export type TasklistUsers = {
  items: TasklistUser[];
};

export type TasklistUserGroups = {
  items: TasklistGroupUser[];
};

export type FormDefinition = {
  id: string;
  processDefinitionKey: string;
  title?: string | null;
  schema?: string | null;
  version?: number | null;
};

export const useUserTasks = (query?: UserTasksQuery) => {
  const endpoint = useMemo(() => addQueryParams('tasklist/tasks', query), [query]);
  // Camunda's default Tasklist uses 5 seconds refresh
  return useSWR<UserTasksPage>(endpoint, { refreshInterval: 10000 });
};

export const useTasklistUsers = () => {
  const endpoint = 'tasklist/users';
  return useSWR<TasklistUsers>(endpoint);
};

export const useTasklistUserGroups = () => {
  const endpoint = 'tasklist/user-groups';
  return useSWR<TasklistUserGroups>(endpoint);
};

export const useInvalidateUserTasks = () => {
  const { mutate } = useSWRConfig();
  const invalidate = useCallback(
    () => mutate(key => !!key && typeof key === 'string' && key.split('?')[0] === 'tasklist/tasks'),
    [mutate]);
  return invalidate;
};

export const useUserTaskDetails = (taskId: string) => {
  const endpoint = `tasklist/tasks/${taskId}`;
  return useSWR<UserTaskDetails>(endpoint);
};

export const useInvalidateUserTaskDetails = () => {
  const { mutate } = useSWRConfig();
  const invalidate = useCallback(
    (taskId: string) => {
      const compareKey = `tasklist/tasks/${taskId}`;
      return mutate(key => key === compareKey);
    },
    [mutate]);
  return invalidate;
};

export const useFormDefinition = (processDefinitionKey: string, formId: string, version?: number | null, enabled: boolean = true) => {
  const endpoint = useMemo(() => enabled
    ? addQueryParams(`tasklist/processDefinitions/${processDefinitionKey}/forms/${formId}`, { version })
    : null,
  [processDefinitionKey, formId, version, enabled]);

  return useSWR<FormDefinition>(endpoint);
};

export const completeTask = async (taskId: string, data: CompleteUserTaskRequest) => {
  const endpoint = `tasklist/tasks/${taskId}/complete`;
  return apiClient.post(endpoint, data);
};

export const saveDraftTaskVariables = async (taskId: string, variables: UserTaskVariableInput[]) => {
  const endpoint = `tasklist/tasks/${taskId}/variables`;
  return apiClient.put(endpoint, variables);
};

export const unassignTask = async (taskId: string) => {
  const endpoint = `tasklist/tasks/${taskId}/assignee`;
  return apiClient.delete(endpoint);
};

export const assignTask = async (taskId: string, data: SetUserTaskAssigneeRequest) => {
  const endpoint = `tasklist/tasks/${taskId}/assignee`;
  return apiClient.put(endpoint, data);
};

export const addTaskComment = async (taskId: string, data: UserTaskCommentInput) => {
  const endpoint = `tasklist/tasks/${taskId}/comments`;
  return apiClient.post(endpoint, data);
};

export const removeTaskComment = async (taskId: string, commentId: string) => {
  const endpoint = `tasklist/tasks/${taskId}/comments/${commentId}`;
  return apiClient.delete(endpoint);
};

export const editTaskComment = async (taskId: string, commentId: string, data: UserTaskCommentInput) => {
  const endpoint = `tasklist/tasks/${taskId}/comments/${commentId}`;
  return apiClient.put(endpoint, data);
};
