import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  Edit, Flag, World2, Paste
} from '@instech/icons';
import { Link } from 'react-router-dom';
import { Page } from '../layout/Page';
import { LinkButton } from '../wrappedComponents';
import { routes } from './routes';
import { useFeatureFlags } from '../../providers/FeatureFlagProvider';

const List = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
`;

const Title = styled.div`
  color: ${props => props.theme.marineBlue};
  font-size: 20px;
  font-weight: bold;
`;

const TaskButton = ({ children, to, icon: Icon }: PropsWithChildren<{to: string, icon: (props: any) => JSX.Element}>) => (
  <Link to={to}>
    <LinkButton startIcon={<Icon width={24} height={24} />}>
      {children}
    </LinkButton>
  </Link>
);

export const Home = () => {
  const { enableFlagChange, enableClassChange, enableTasklist } = useFeatureFlags();
  return (
    <Page>
      <Title>Select task</Title>
      <List>
        <TaskButton to={routes.renameVessel} icon={Edit}>Rename vessel</TaskButton>
        {enableFlagChange && <TaskButton to={routes.changeFlag} icon={Flag}>Change vessel flag</TaskButton>}
        {enableClassChange && <TaskButton to={routes.changeClass} icon={World2}>Change vessel class</TaskButton>}
      </List>
      {enableTasklist && (
        <>
          <Title>Tasklist</Title>
          <List>
            <TaskButton to={`${[routes.tasklist.base, routes.tasklist.userTasks].join('/')}?state=Created&assignee=me`} icon={Paste}>View tasklist</TaskButton>
          </List>
        </>
      )}
    </Page>
  );
};
