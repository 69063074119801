import React, { FunctionComponent, PropsWithChildren } from 'react';
import {
  BoxButton as BoxButtonComponent,
  BoxButtonProps,
  Pane as PaneComponent,
  PaneProps,
  ErrorBoundary as ErrorBoundaryComponent,
  DefaultErrorMessage,
  LinkButton as LinkButtonComponent,
  LinkButtonProps,
  SlimButton as SlimButtonComponent,
  SlimButtonProps,
  ButtonGroup as ButtonGroupComponent,
  ButtonGroupProps
} from '@instech/components';

// BoxButton is not React 18 compatible yet, so we need a wrapper to ignore warnings
// @ts-ignore:next-line
export const BoxButton = (props: PropsWithChildren<BoxButtonProps>) =>
  <BoxButtonComponent {...props}>{props.children}</BoxButtonComponent>;

export const Button = (props: PropsWithChildren<SlimButtonProps>) =>
  <SlimButtonComponent {...props}>{props.children}</SlimButtonComponent>;

export const ButtonGroup = (props: PropsWithChildren<ButtonGroupProps>) =>
  <ButtonGroupComponent {...props}>{props.children}</ButtonGroupComponent>;

// Pane is not React 18 compatible yet, so we need a wrapper to ignore warnings
// @ts-ignore:next-line
export const Pane = (props: PropsWithChildren<PaneProps>) =>
  <PaneComponent {...props}>{props.children}</PaneComponent>;

interface ErrorBoundaryProps {
  component?: FunctionComponent<{
    message?: string;
    reset: () => void;
  }>;
}
// ErrorBoundary is not React 18 compatible yet, so we need a wrapper to ignore warnings
// @ts-ignore:next-line
export const ErrorBoundary = (props: PropsWithChildren<ErrorBoundaryProps>) =>
  <ErrorBoundaryComponent component={props.component ?? DefaultErrorMessage} {...props}>{props.children}</ErrorBoundaryComponent>;

// LinkButton now explicitly declares children as required, but they are actually optional
export const LinkButton = (props: PropsWithChildren<Omit<LinkButtonProps, 'children'>>) =>
  <LinkButtonComponent {...props}>{props.children}</LinkButtonComponent>;
