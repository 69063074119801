import {
  InsuranceObject, RenameObjectRequest, renameObjectAsync
} from '../../../services/objectsService';
import { RenameFormValues } from './types';
import { getDateOnlyString } from '../../../utils/date';
import { SelectedVessel } from '../../../utils/vessel';
import { getEmailRecipients } from '../../form/utils';
import { OperationInstance } from '../../../hooks/useOperation';

const getRenameObjectRequest = (values: RenameFormValues, vessel: InsuranceObject): RenameObjectRequest => ({
  insuranceYear: vessel.insuranceYear,
  objectNumber: vessel.objectNumber,
  oldName: vessel.name,
  newName: values.newName,
  effectiveDate: getDateOnlyString(values.effectiveDate),
  fleetName: vessel.fleetName,
  contractPeriodFrom: vessel.contractPeriodFrom,
  contractPeriodTo: vessel.contractPeriodTo,
  confirmationEmailRecipients: getEmailRecipients(values.emailRecipients)
});

const handleRenameVessel = async (values: RenameFormValues, vessel: InsuranceObject) => {
  const request = getRenameObjectRequest(values, vessel);
  return renameObjectAsync(request);
};

export const canSubmit = (values: RenameFormValues, selectedVessels: SelectedVessel[]) =>
  !!(values.newName && values.effectiveDate && selectedVessels.length > 0);

export const createOperations = (
  vessels: InsuranceObject[],
  values: RenameFormValues
): OperationInstance[] =>
  vessels
    .map(vessel => ({
      instanceId: `${vessel.insuranceYear}-${vessel.objectNumber}`,
      title: `Renaming vessel ${vessel.name} (${vessel.insuranceYear}) -> ${values.newName}`,
      progressTopic: `/Objects/${vessel.insuranceYear}/${vessel.objectNumber}/NameChange/*`,
      startRequest: () => handleRenameVessel(values, vessel)
    }));
