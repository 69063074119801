import React, { FC, useState } from 'react';
import { isRealNumber } from '../utils/number';
import { arrayPartition } from '../utils/array';
import {
  TableRow, TableRowData, TableRowSegment
} from './types';
import {
  RowNavigationHandler, RowPropsProvider, TableRowWithColor
} from '../core/TableRow';
import { useSharedTooltip } from '../shared/Tooltip/SharedTooltip';
import { ExpandableRowButton } from '../ExpandableRowButton';
import { TableRowCell } from '../core/TableRowCell';
import { ExpandableRowWrapper } from '../ExpandableTableRow';
import { SortableTableSubrow } from './SortableTableSubrow';

const createCellProps = (row: TableRow, segment: TableRowSegment) => {
  const mapKey = `${row.id}-${segment.key}`;
  const props = {
    'data-cell-variant': segment.variant,
    'right': segment.align === 'right',
    'underline': segment.underline,
    'maxContent': segment.maxContent,
  };
  return { mapKey, props };
};

interface TableRowProps {
  rowData: TableRowData;
  even: boolean;
  favourites?: string[];
  minSubrowsToExpand?: number;
  showFullComments?: boolean;
  expandAllSubrows?: boolean;
  customProps?: Record<string, any>;
}
const SortableTableRow: FC<TableRowProps> = ({
  rowData,
  even,
  favourites = [],
  minSubrowsToExpand,
  showFullComments = false,
  expandAllSubrows = false,
  customProps
}) => {
  const [expanded, setExpanded] = useState(false);
  const { tooltipId } = useSharedTooltip();
  const { row, subrows } = rowData;

  // If any columns should render before the subrow expand, partition the segments now
  const [beforeSubrowColumn, afterSubrowColumn] = arrayPartition(row.segments, elem => !!elem.beforeSubrows);

  const handleRowExpand = () => {
    setExpanded(val => !val);
  };

  const subrowCount = subrows?.length || 0;
  const rowCanExpand = !!minSubrowsToExpand && (subrowCount >= minSubrowsToExpand);
  const isRowExpanded = expanded || expandAllSubrows;
  const isFavourite = favourites.includes(row.id);

  return (
    <RowPropsProvider tooltipId={tooltipId} showFullComments={showFullComments} customProps={customProps}>
      <TableRowWithColor even={even} isFaded={row.isFaded} isActive={!!row.onClick} isFavourite={isFavourite}>
        <RowNavigationHandler onClick={row.onClick}>
          {beforeSubrowColumn.map(segment => {
            const { mapKey, props } = createCellProps(row, segment);
            return <TableRowCell key={mapKey} segment={segment} {...props} />;
          })}
        </RowNavigationHandler>
        {isRealNumber(minSubrowsToExpand) && (
          <ExpandableRowButton
            rowId={row.id}
            count={subrowCount}
            isVisible={rowCanExpand}
            isToggled={isRowExpanded}
            onClick={handleRowExpand} />
        )}
        <RowNavigationHandler onClick={row.onClick}>
          {afterSubrowColumn.map(segment => {
            const { mapKey, props } = createCellProps(row, segment);
            return <TableRowCell key={mapKey} segment={segment} {...props} />;
          })}
        </RowNavigationHandler>
      </TableRowWithColor>
      {(rowCanExpand && subrows) && (
        <ExpandableRowWrapper isExpanded={isRowExpanded}>
          {subrows.map((subrow, index) => (
            <SortableTableSubrow
              key={subrow.id}
              subrow={subrow}
              even={index % 2 === 0}
            />
          ))}
        </ExpandableRowWrapper>
      )}
    </RowPropsProvider>
  );
};

interface TableRowsProps {
  tableRows: TableRowData[];
  favourites?: string[];
  minSubrowsToExpand?: number;
  showFullComments?: boolean;
  expandAllSubrows?: boolean;
  customProps?: Record<string, any>;
}
/**
 * Takes a list of TableRowData and return a list of table rows for use inside of
 * a <ComplexTable />, as invidual table cells. This version does not support the
 * ability to collapse parts of the table. It is only meant for simpler, but still
 * sortable tables.
 *
 * @prop {showFullComments}: Show any Comment cell in the table's without truncating text.
 *
 * @prop {expandAllSubrows}: Show all Subrows as if toggled to be expanded.
 */
export const SortableTableRows: FC<TableRowsProps> = ({
  tableRows,
  favourites,
  minSubrowsToExpand,
  showFullComments,
  expandAllSubrows,
  customProps
}) => {
  if (!tableRows) {
    return null;
  }
  return (
    <>
      {tableRows.map((rowData, i) => (
        <SortableTableRow
          key={rowData.row.id}
          even={i % 2 === 0}
          rowData={rowData}
          favourites={favourites}
          minSubrowsToExpand={minSubrowsToExpand}
          showFullComments={showFullComments}
          expandAllSubrows={expandAllSubrows}
          customProps={customProps}
        />
      ))}
    </>
  );
};
