import React, {
  createContext, useContext, ReactElement, useMemo, PropsWithChildren
} from 'react';
import { ModalOptions } from './types';

interface ContextData {
  open: (component: any) => void;
  close: () => void;
}
const ModalContext = createContext<ContextData>(null as unknown as ContextData);

export const ModalProvider = ({ children, open, close }: PropsWithChildren<ContextData>) => {
  const value = useMemo(() => ({
    open, close
  }), [open, close]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

interface ModalProps<T> {
  component: ((props: T) => ReactElement) | ReactElement;
  options: ModalOptions
  args?: any;
}
export const useModal = <T, >(props: ModalProps<T>) => {
  const { open, close } = useModalContext();
  const payload = {
    component: props.component,
    options: { ...props.options },
    args: { ...props.args }
  };
  return {
    open: () => open(payload),
    close
  };
};
