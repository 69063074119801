import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParams = <T extends string>(paramKeys: readonly T[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = useCallback((newParams?: Partial<Record<T, string | null> | null>) => {
    const newSearchParams = new URLSearchParams(searchParams);
    paramKeys.forEach(key => {
      const value = newParams ? newParams[key] : null;
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });
    setSearchParams(newSearchParams);
  }, [paramKeys, searchParams, setSearchParams]);

  const setParam = useCallback((key: T, value: string | null | undefined) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
  }, [searchParams, setSearchParams]);

  const params = useMemo(
    () => paramKeys.reduce(
      (res, param) => ({ ...res, [param]: searchParams.get(param) }),
      {} as Record<T, string | null>),
    [paramKeys, searchParams]);

  return {
    setParams,
    setParam,
    params
  };
};
