import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Remove } from '@instech/icons';
import { IconButton } from '@instech/components';
import { ModalOptions } from './types';

const onClickHandler = (fn: () => void) => (evt: React.MouseEvent<HTMLElement>) => evt.target === evt.currentTarget && fn();

const ModalScroll = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${props => props.zIndex ?? props.theme.zIndex.modal};
  overflow-y: scroll;
`;

const Overlay = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 40px;
  background-color: rgba(0,0,0,0.65);
  box-sizing: border-box;
`;

const Container = styled.div`
  pointer-events: none;
  background: none;
  position: relative;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
`;

const LayoutArea = styled.div<{ size?: string }>`
  pointer-events: auto;
  width: 100%;
  max-width: 1110px;
  background: #fff;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  display: flex;
  flex-direction: column;

  ${props => props.size === 'medium' && css`
    max-width: 716px;
  `};

  ${props => props.size === 'small' && css`
    max-width: 512px;
  `};
`;

const ModalHeader = styled.div<{ hideTitle?: boolean }>`
  display: flex;
  flex-direction: ${props => props.hideTitle ? 'row-reverse' : 'row'};
  justify-content: space-between;
  height: 40px;
  padding-left: 20px;
  padding-right: 8px;
  background-color: ${props => props.hideTitle ? '#fff' : props.theme.flatWhite};
`;

const ModalTitle = styled.span`
  padding-top: 11px;
  padding-bottom: 13px;
  font-weight: bold;
`;

const ModalBody = styled.div`
  padding: 32px;
  > * ~ * { /* Impose spacing between direct descendants */
    margin-top: 32px;
  }
`;

interface ModalLayoutProps {
  options?: ModalOptions;
  closeModal: () => void;
  zIndex?: number;
}
export const ModalLayout = ({ children, options = {}, closeModal, zIndex = 100 }: PropsWithChildren<ModalLayoutProps>) => (
  <ModalScroll zIndex={zIndex}>
    <Overlay onClick={onClickHandler(closeModal)}>
      <Container role="dialog">
        <LayoutArea size={options.size}>
          {options.title && (
            <ModalHeader hideTitle={options.hideTitle}>
              {!options.hideTitle && <ModalTitle>{options.title}</ModalTitle>}
              <IconButton
                icon={<Remove />}
                onClick={closeModal}
              />
            </ModalHeader>
          )}
          <ModalBody>
            {children}
          </ModalBody>
        </LayoutArea>
      </Container>
    </Overlay>
  </ModalScroll>
);
