import React from 'react';
import {
  CheckboxControlled,
  InformationNotification,
  Loader,
  SimpleTable, TableCell, TableHeader, getFriendlyDate
} from '@instech/components';
import { DisabledRow } from '../customStyledComponents';
import { Pane } from '../../wrappedComponents';
import { InsuranceObject } from '../../../services/objectsService';
import { constructId, SelectedVessel } from '../../../utils/vessel';

interface VesselRowProps {
  vessel: InsuranceObject;
  index: number;
  selected: boolean;
  onChange: (id: string, selected: boolean) => void;
  selectedVessels: SelectedVessel[];
}

const VesselRow = ({ vessel, index, selected, onChange, selectedVessels }: VesselRowProps) => {
  const id = constructId(vessel.id, vessel.fleetName, vessel.contractPeriodFrom, vessel.contractPeriodTo);

  const disabledRowClass = selectedVessels.some(s => s.id === id && !s.isClicked) ? 'disabled-row' : '';

  return (
    <DisabledRow even={index % 2 === 0} className={disabledRowClass}>
      <TableCell>
        <CheckboxControlled
          noTopLabel
          name={id}
          selected={selected}
          onChange={() => onChange(id, !selected)}
        />
      </TableCell>
      <TableCell>{vessel.name}</TableCell>
      <TableCell>{vessel.imoNumber}</TableCell>
      <TableCell>{vessel.insuranceYear}</TableCell>
      <TableCell>{vessel.fleetName}</TableCell>
      <TableCell>{getFriendlyDate(vessel.contractPeriodFrom)}</TableCell>
      <TableCell>{getFriendlyDate(vessel.contractPeriodTo)}</TableCell>
    </DisabledRow>
  );
};

const NoVesselsFound = () =>
  <InformationNotification size="medium" headingText="No vessels found" descriptionText="Try using different search parameters" />;

interface VesselSelectorProps {
  vessels: InsuranceObject[];
  isLoading: boolean;
  selectVessel: (id: string, selected: boolean) => void;
  selectedVessels: SelectedVessel[];
}

export const VesselSelector = ({ vessels, isLoading, selectVessel, selectedVessels }: VesselSelectorProps) => {
  const vesselRows = vessels.map((vessel, index) => {
    const id = constructId(vessel.id, vessel.fleetName, vessel.contractPeriodFrom, vessel.contractPeriodTo);

    return (
      <VesselRow
        key={id}
        vessel={vessel}
        index={index}
        selected={selectedVessels.some(s => s.id === id)}
        onChange={selectVessel}
        selectedVessels={selectedVessels}
      />
    );
  });

  return (
    <Pane title="Select vessels">
      <SimpleTable columns={7}>
        <TableHeader>Rename?</TableHeader>
        <TableHeader>Vessel name</TableHeader>
        <TableHeader>IMO number</TableHeader>
        <TableHeader>Insurance year</TableHeader>
        <TableHeader>Fleet name</TableHeader>
        <TableHeader>Contract period from</TableHeader>
        <TableHeader>Contract period to</TableHeader>
        {vesselRows}
      </SimpleTable>
      {isLoading && <Loader />}
      {(!vessels.length && !isLoading) && <NoVesselsFound />}
    </Pane>
  );
};
