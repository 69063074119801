import { LabelValuePair } from '@instech/components';
import { InsuranceObject } from '../../services/objectsService';
import { getSelectedVesselObjects, SelectedVessel } from '../../utils/vessel';

export const getEmailRecipients = (recipients: string) =>
  recipients.length > 0 ? recipients.split(',').map(email => email.trim()) : [];

export type Option = string | LabelValuePair;

export const getOptionValue = (option: Option) => typeof option === 'string' ? option : option.value;
export const getOptionLabel = (option: Option) => typeof option === 'string' ? option : option.label;

export const effectiveDateIsAfterInsurancePeriods = (vessels: InsuranceObject[], selectedVessels: SelectedVessel[], effectiveDate: Date) => {
  const insurancePeriods = getSelectedVesselObjects(vessels, selectedVessels).map(v => new Date(v.insurancePeriodTo));

  return insurancePeriods.some(d => d < effectiveDate);
};

// Only allow format used for display in date picker or ISO8601 extended format (used for value set by datepicker)
// - We do NOT want to allow american format etc due to ambiguity
// Display format is used if user enters into textfield manually (ex: 11 Jan 2024)
const displayFormatPattern = /^[1-3]?[1-9] [A-Za-z]{3} \d\d\d\d$/;
// Extended UTC format is used if date is selected in date picker
const utcFormatPattern = /^\d{4}-\d{2}-\d{2}(T\d{2}(:\d{2})?(:\d{2})?(.\d{1,10})?(Z|\+\d{2}:\d{2})?)?$/;
const isInAllowedDateInputFormat = (rawValue: string) => utcFormatPattern.test(rawValue) || displayFormatPattern.test(rawValue);

export const tryParseDateInput = (rawValue: string): [isValid: true, date: Date] | [isValid: false, date?: null] => {
  // Check to see if value looks like it follows allowed formats
  if (!isInAllowedDateInputFormat(rawValue)) {
    return [false];
  }
  // Check to see if the value is actually parseable
  const parsedDateTimestamp = Date.parse(rawValue);
  if (!parsedDateTimestamp) {
    return [false];
  }
  return [true, new Date(parsedDateTimestamp)];
};

export const validateDateInput = (rawValue: string, isRequired: boolean = false): [isValid: true, error?: null] | [isValid: false, error: string] => {
  if (!rawValue) {
    return isRequired ? [false, 'Field is required'] : [true];
  }
  const [isValid] = tryParseDateInput(rawValue);
  if (!isValid) {
    return [false, 'Invalid date format. Expected format in the form of "12 Jan 2024"'];
  }
  return [true];
};
