import { useState } from 'react';
import { InsuranceObject } from '../services/objectsService';
import {
  constructId,
  deconstructId,
  SelectedVessel
} from '../utils/vessel';

export const useVesselSelection = (vessels: InsuranceObject[]) => {
  const [selectedVessels, setSelectedVessels] = useState<SelectedVessel[]>([]);

  const addVessels = (newVessels: SelectedVessel[]) => {
    setSelectedVessels(prev => {
      const updated = prev.filter(v => !newVessels.some(nv => nv.id === v.id));
      return [...updated, ...newVessels];
    });
  };

  const removeVessels = (ids: string[]) =>
    setSelectedVessels(prev => prev.filter(v => !ids.includes(v.id)));

  const selectVessel = (id: string, selected: boolean) => {
    const filteredVessels = vessels.filter(v => v.id === deconstructId(id));
    if (filteredVessels.length === 0) return;

    const matchingVesselIds = filteredVessels.map(v => constructId(v.id, v.fleetName, v.contractPeriodFrom, v.contractPeriodTo));

    if (selected) {
      const newSelection = matchingVesselIds.map(vesselId => ({
        id: vesselId,
        isClicked: vesselId === id,
      }));
      addVessels(newSelection);
    } else {
      removeVessels(matchingVesselIds);
    }
  };

  const resetVessels = () => setSelectedVessels([]);

  return { selectedVessels, selectVessel, resetVessels };
};
