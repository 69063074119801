import useSWR from 'swr';

export interface ApplicationRole {
  name: string;
  displayName: string;
}

export const useRoles = () => {
  const requestUrl = '/roles';
  return useSWR<ApplicationRole[]>(requestUrl);
};
