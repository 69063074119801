import React from 'react';
import styled from 'styled-components';

const PageLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  padding: 20px;
  & > div {
    max-width: 600px;
  }
`;

const Title = styled.h2`
  font-size: 30px;
  font-weight: unset;
  color: ${props => props.theme.marineBlue};
  margin: 0px 0px 12px 0px;
`;

const Text = styled.p`
  font-size: 16px;
  margin: 0px;
`;

export const NoAccess = () => (
  <div>
    <Title>You don&apos;t have access to TaskFlow.</Title>
    <Text>If you need access, please reach out to the product owner.</Text>
  </div>
);

export const NoAccessPage = () => (
  <PageLayout>
    <NoAccess />
  </PageLayout>
);
