import {
  useEffect, useMemo, useState
} from 'react';
import { useDebouncedFn } from './useDebouncedFn';

const checkIsLocalStorageAvailable = () => {
  const testKey = 'isLocalStorageAvailableTest';
  try {
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch {
    return false;
  }
};

const tryGetFromLocalStorage = <T>(key: string): [hasValue: boolean, value: T | undefined] => {
  try {
    const storedRawValue = localStorage.getItem(key);
    return [!!storedRawValue, storedRawValue ? JSON.parse(storedRawValue) as T : undefined];
  } catch {
    // eslint-disable-next-line no-console
    console.error(`Failed to parse stored state: ${key}`);
  }
  return [false, undefined];
};

/**
 * Wraps useState with logic to try to retrieve initial value from localStorage if a key is set.
 * It also persist updates to this value to localStorage for next time the consumer is mounted.
 * Stored value is only loaded on initial mount/render, no active sync between tabs etc.
 */
export const useStoredState = <T>(
  defaultValue: T | undefined,
  key: string | undefined
) => {
  const canUseLocalStorage = useMemo(checkIsLocalStorageAvailable, []);

  const useLocalStorage = canUseLocalStorage && !!key;

  const [hasStoredDefaultValue, storedDefaultValue] = useMemo(
    () => useLocalStorage ? tryGetFromLocalStorage<T>(key) : [false, undefined],
    [key, useLocalStorage]
  );

  const useStateResult = useState(hasStoredDefaultValue ? storedDefaultValue : defaultValue);

  const currentStateValue = useStateResult[0];

  const saveToLocalStorage = useDebouncedFn((newValue: T | null | undefined) => {
    if (!useLocalStorage) return;
    if (!hasStoredDefaultValue || storedDefaultValue !== newValue) {
      localStorage.setItem(key, JSON.stringify(newValue));
    }
  });

  useEffect(
    () => saveToLocalStorage(currentStateValue),
    [saveToLocalStorage, currentStateValue]
  );

  return useStateResult;
};
