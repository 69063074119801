import { useMemo } from 'react';
import { TableDefinition } from './tableDefinition';
import { SortableTableSchemaReturn } from './Table/SortableTable/types';
import { processTableDefinition } from './utils';

export type Table = SortableTableSchemaReturn & {
  facets: { [propName: string]: Set<any> }
  hasSubRows: boolean;
}

/**
 * Hook to transform a collection and a table definition to the table structure used by internal table component
 */
export const useTable = <TMainRow, TSubRow>(data: TMainRow[], tableDefinition: TableDefinition<TMainRow, TSubRow>): Table => {
  const { structure, tableRowsFactory, tableFacetsFactory } = useMemo(() => processTableDefinition(tableDefinition), [tableDefinition]);
  const tableRows = useMemo(() => tableRowsFactory(data), [data, tableRowsFactory]);
  const facets = useMemo(() => tableFacetsFactory(tableRows), [tableRows, tableFacetsFactory]);
  return useMemo(() => ({ ...structure, tableRows, facets }), [structure, tableRows, facets]);
};
