import React, {
  useEffect, useMemo, useRef
} from 'react';
import { Schema } from '@bpmn-io/form-js-viewer';
import { Loader } from '@instech/components';
import { useFormDefinition } from '../../services/userTasksService';
import { CamundaFormViewer, CamundaFormViewerProps } from './CamundaFormViewer';
import { FormJsErrorMessage } from './FormJsErrorMessage';

type CamundaFormLoaderProps = Omit<CamundaFormViewerProps, 'schema'> & {
  formId: string;
  processDefinitionKey: string;
  version?: number | null;
  onEmptyFormSchema?: () => void;
}

export const CamundaFormLoader = ({
  formId,
  processDefinitionKey,
  version,
  className,
  onEmptyFormSchema,
  ...restProps
}: CamundaFormLoaderProps) => {
  const { data: formDefinition, isLoading } = useFormDefinition(processDefinitionKey, formId, version);

  const schema = useMemo<Schema>(() => formDefinition?.schema ? JSON.parse(formDefinition.schema) : null, [formDefinition?.schema]);
  const onEmptyFormSchemaRef = useRef(onEmptyFormSchema);

  useEffect(() => {
    if (formDefinition && !schema) onEmptyFormSchemaRef.current?.();
  }, [formDefinition, schema]);

  if (isLoading) return <Loader />;

  if (!schema) return <FormJsErrorMessage className={className}>No form defined for this task</FormJsErrorMessage>;

  return (
    <CamundaFormViewer {...restProps} className={className} schema={schema} />
  );
};
