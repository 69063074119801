/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useRef, useState, Suspense, PropsWithChildren, useCallback, FunctionComponent
} from 'react';
import { createPortal } from 'react-dom';
import {
  Loader, ErrorBoundary, DefaultErrorMessage
} from '@instech/components';
import { ModalProvider } from './ModalContext';
import { ModalLayout } from './ModalLayout';
import { ModalOptions } from './types';

let modalRoot = document.getElementById('modal');
if (!modalRoot) {
  modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal');
  modalRoot.setAttribute('data-testid', 'modal');
  document.body.appendChild(modalRoot);
}

interface ModalProps {
  options: ModalOptions;
  closeModal: () => void;
}
const Modal = ({ children, options, closeModal }: PropsWithChildren<ModalProps>) => {
  const elRef = useRef<Element | null>(null);
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    if (elRef.current && modalRoot) {
      modalRoot.appendChild(elRef.current);
    }
    document.body.style.overflow = 'hidden';

    return () => {
      if (elRef.current && modalRoot) {
        modalRoot.removeChild(elRef.current);
      }
      document.body.style.overflow = '';
    };
  }, []);

  return createPortal(
    <ModalLayout options={options} closeModal={closeModal}>{children}</ModalLayout>,
    elRef.current
  );
};

interface ModalContentProps {
  component: FunctionComponent;
  args: any;
}

const ModalContent = ({ component: Component, args }: ModalContentProps) => <Component {...args} />;

interface ModalHandlerProps extends ModalProps, ModalContentProps { }

export const ModalHandler = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<ModalHandlerProps | null>(null);
  const close = useCallback(() => setModal(null), []);
  const ErrorComponent = useCallback(() => <DefaultErrorMessage reset={close} />, [close]);
  return (
    <ModalProvider open={setModal} close={close}>
      {children}
      {modal && (
        <Modal options={modal.options} closeModal={close}>
          <ErrorBoundary component={ErrorComponent}>
            <Suspense fallback={<Loader />}>
              <ModalContent
                component={modal.component}
                args={modal.args}
              />
            </Suspense>
          </ErrorBoundary>
        </Modal>
      )}
    </ModalProvider>
  );
};
