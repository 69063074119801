import React, {
  RefObject, useCallback, useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';

const ResizerBar = styled.div<{ isResizing: boolean }>`
  cursor: col-resize;
  position: relative;

  &:before {
    display: block;
    position: absolute;
    content: ' ';
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 1px;
    height: 100%;
    background-color: ${({ isResizing, theme }) => isResizing ? theme.placeholderGray : 'transparent'};
    transition: background-color 0.25s ease-in-out;
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.placeholderGray};
  }
`;

type ResizeHandler = (newWidthPercentage: number) => void;
type ColumnResizerProps = {
  containerRef: Readonly<RefObject<HTMLElement>>;
  minLeftPercentage?: number;
  maxLeftPercentage?: number;
  onResize: ResizeHandler;
}
export const ColumnResizer = ({ containerRef, onResize, minLeftPercentage = 0, maxLeftPercentage = 100 }: ColumnResizerProps) => {
  const [isResizing, setIsResizing] = useState(false);
  const onResizeRef = useRef<ResizeHandler>(onResize);
  onResizeRef.current = onResize;

  const resizerRef = useRef<HTMLDivElement>(null);

  const settingsRef = useRef({ minLeftPercentage, maxLeftPercentage });
  settingsRef.current = { minLeftPercentage, maxLeftPercentage };

  const handleMove = useCallback((moveEvent: MouseEvent) => {
    if (!containerRef.current || !resizerRef.current) return;

    // Subtract padding and border from bounding rectangle to get content placement and size
    const containerClientRect = containerRef.current.getBoundingClientRect();
    const containerStyle = window.getComputedStyle(containerRef.current);
    const containerPaddingLeft = parseFloat(containerStyle.paddingLeft);
    const containerPaddingRight = parseFloat(containerStyle.paddingRight);
    const containerBorderLeft = parseFloat(containerStyle.borderLeftWidth);
    const containerBorderRight = parseFloat(containerStyle.borderRightWidth);
    const containerContentLeft = containerClientRect.left + containerPaddingLeft + containerBorderLeft;
    const containerContentWidth = containerClientRect.width - containerBorderLeft - containerPaddingLeft - containerPaddingRight - containerBorderRight;

    // Offset half of resizer bar to get width left of bar, when bar is centered on mouse
    const resizerBarOffset = resizerRef.current.clientWidth / 2;

    // Calculate percentage of container content left of resize bar
    const relativeLeft = moveEvent.clientX - containerContentLeft - resizerBarOffset;
    const percentageLeft = (relativeLeft / containerContentWidth) * 100;
    const settings = settingsRef.current;

    // Keep within min/max percentage
    const boundedPercentageLeft = Math.min(Math.max(percentageLeft, settings.minLeftPercentage), settings.maxLeftPercentage);

    onResizeRef.current?.(boundedPercentageLeft);
  }, [containerRef]);

  const startResizing = useCallback(() => {
    setIsResizing(true);
    document.body.addEventListener('mousemove', handleMove);
  }, [handleMove]);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
    document.body.removeEventListener('mousemove', handleMove);
  }, [handleMove]);

  useEffect(() => {
    document.body.addEventListener('mouseup', stopResizing);
    return () => {
      stopResizing();
      document.body.removeEventListener('mouseup', stopResizing);
    };
  }, [stopResizing]);

  return <ResizerBar isResizing={isResizing} ref={resizerRef} role="presentation" onMouseDown={startResizing} />;
};
