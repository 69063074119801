import React, {
  useCallback, useMemo, useState
} from 'react';
import styled from 'styled-components';
import { DropdownControlled, Loader } from '@instech/components';
import { Form } from '@bpmn-io/form-js-viewer';
import { Comment } from '@instech/icons';
import { FormEvent, FormVariables } from './CamundaFormViewer';
import {
  UserTaskVariable,
  UserTaskVariableInput,
  completeTask,
  useUserTaskDetails,
  useInvalidateUserTaskDetails,
  useInvalidateUserTasks,
  unassignTask,
  assignTask,
  useTasklistUsers,
  saveDraftTaskVariables,
  useTasklistUserGroups,
  getAssigneeFromUser
} from '../../services/userTasksService';
import { CamundaFormLoader } from './CamundaFormLoader';
import { useIsMounted } from '../../hooks/useIsMounted';
import { FormJsErrorMessage } from './FormJsErrorMessage';
import { SlimSubmitButton, SubmitButton } from '../buttons/SubmitButton';
import { extractErrorInfo } from '../../utils/errors';
import { usePredicateMemo } from '../../hooks/usePredicateMemo';
import { toLongDateTime } from '../../utils/date';
import { CamundaTaskState } from './CamundaTaskState';
import { ContentPane } from '../layout/ContentPane';
import { Button } from '../wrappedComponents';
import { CamundaTaskComments } from './CamundaTaskComments';
import { useAccount } from '../../providers/AuthenticationProvider';

const TaskHeading = styled.h1`
  font-size: 24px;
  margin-top: 0;
`;

const TaskHeaderTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const TaskHeaderContextActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

const AssigneeContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  > *:first-child {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const SmallLoader = styled(Loader)`
  height: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  button[disabled] {
    cursor: default;
    opacity: 0.8;
  }

  .only-assigned-box {
    text-align: right;

    button {
      padding: 0;
      min-height: 0;
    }

    > div {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const unwrapVariables = (variables: UserTaskVariable[]): FormVariables =>
  variables.reduce((result, variable) => {
    const rawValue = variable.draftValue || variable.value;
    return { ...result, [variable.name]: rawValue ? JSON.parse(rawValue) : null };
  }, {});

const wrapVariables = (variables: FormVariables) =>
  Object.entries(variables).map<UserTaskVariableInput>(([key, value]) => ({
    name: key,
    value: JSON.stringify(value),
  }));

const areVariablesEquivalent = (someVariables: UserTaskVariable[] | undefined, otherVariables: UserTaskVariable[] | undefined): boolean => {
  if (!someVariables) return !otherVariables;
  if (someVariables.length !== otherVariables?.length) return false;
  return someVariables.every((variable, index) => {
    const otherVariable = otherVariables[index];
    return variable.name === otherVariable.name &&
      variable.value === otherVariable.value &&
      variable.draftValue === otherVariable.draftValue;
  });
};

const hasErrors = (e: FormEvent) => !!e.errors && !!Object.keys(e.errors).length;

export type TaskContextAction = {
  condition?: (variables: FormVariables) => boolean;
  label: (variables: FormVariables) => string;
  startIcon?: JSX.Element;
  onClick: (variables: FormVariables) => void | Promise<any>
};

type CamundaTaskLoaderProps = {
  taskId: string;
  fitParent: boolean;
  contextActions?: TaskContextAction[];
  onComplete?: () => void;
}

type AssigneeOption = { label: string; value: string | '' };

const unassignedOption: AssigneeOption = { label: 'Unassigned', value: '' };

export const CamundaTask = ({ taskId, fitParent, contextActions, onComplete }: CamundaTaskLoaderProps) => {
  const isMounted = useIsMounted();
  const { user } = useAccount();

  const { data: tasklistUsers } = useTasklistUsers();
  const { data: tasklistUserGroups } = useTasklistUserGroups();
  const tasklistUsersAndGroups = useMemo(() =>
    [...(tasklistUsers?.items || []), ...(tasklistUserGroups?.items || [])], [tasklistUsers, tasklistUserGroups]);
  const { data: task, isLoading } = useUserTaskDetails(taskId);
  const invalidateTasks = useInvalidateUserTasks();
  const invalidateTaskDetails = useInvalidateUserTaskDetails();

  // deep compare variables to avoid unnecessary form resets on assign/unassign
  const variables = usePredicateMemo(
    () => unwrapVariables(task?.variables || []),
    ([prevTaskVariables]) => areVariablesEquivalent(task?.variables, prevTaskVariables),
    [task?.variables]
  );

  const [showComments, setShowComments] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [isUpdatingAssignee, setIsUpdatingAssignee] = useState(false);
  const [updateAssigneeError, setUpdateAssigneeError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const [loadedTaskForm, setLoadedTaskForm] = useState<{ form: Form | null }>();

  const isAssigned = !!task?.assigneeInfo;
  const isAssignedToCurrentUser = isAssigned && task.assigneeInfo?.assignee === user?.email;
  const isAssignedToDifferentUser = isAssigned && !isAssignedToCurrentUser;
  const isOpen = task?.taskState === 'Created';

  const canChangeAssignee = task?.taskState === 'Created';
  const canSubmit = !!loadedTaskForm && isOpen && !isAssignedToDifferentUser && !(submitAttempted && hasValidationErrors);

  const assigneeOptions = useMemo((): AssigneeOption[] => [
    unassignedOption,
    ...(task?.assigneeInfo && tasklistUsersAndGroups.every(u => getAssigneeFromUser(u) !== task.assigneeInfo?.assignee)
      ? [{ label: task.assigneeInfo.name?.trim() || task.assigneeInfo.assignee, value: task.assigneeInfo.assignee }]
      : []),
    ...(tasklistUsers?.items.map(u => ({ label: u.name?.trim() || u.email, value: u.email })) || []),
    ...(tasklistUserGroups?.items.map(u => ({ label: u.name?.trim() || u.id, value: u.id })) || []),
  ], [task?.assigneeInfo, tasklistUserGroups, tasklistUsers, tasklistUsersAndGroups]);

  const currentAssigneeOption = useMemo(() => {
    const currentAssigneeEmail = task?.assigneeInfo?.assignee;
    if (!currentAssigneeEmail) return unassignedOption;
    return assigneeOptions.find(o => o.value === currentAssigneeEmail);
  }, [assigneeOptions, task?.assigneeInfo?.assignee]);

  const activeContextActions = useMemo(() => task
    ? contextActions?.filter(a => !a.condition || a.condition(variables)).map(a => ({
      label: a.label(variables),
      startIcon: a.startIcon,
      onClick: () => a.onClick(variables)
    }))
    : [], [contextActions, task, variables]);

  const handleChangeAssignee = useCallback(async (assigneeChange: () => Promise<any>) => {
    if (!canChangeAssignee) return;
    try {
      setIsUpdatingAssignee(true);
      await assigneeChange();
      await invalidateTaskDetails(taskId);
    } catch (e) {
      const errorInfo = extractErrorInfo(e);
      if (isMounted.current) setUpdateAssigneeError(errorInfo.message);
    } finally {
      if (isMounted.current) setIsUpdatingAssignee(false);
    }
    void invalidateTasks();
  }, [canChangeAssignee, invalidateTasks, invalidateTaskDetails, taskId, isMounted]);

  const handleAssignToMe = useCallback(async () => {
    const currentUserEmail = user?.email;
    if (!currentUserEmail) throw new Error('No current user in context');
    await handleChangeAssignee(() => assignTask(taskId, { assignee: currentUserEmail }));
  }, [handleChangeAssignee, taskId, user?.email]);

  const handleAssigneeSelectionChange = useCallback((selectedOption?: AssigneeOption | null) => {
    const oldAssigneeEmail = task?.assigneeInfo?.assignee || null;
    const newAssigneeEmail = selectedOption?.value || null;
    if (oldAssigneeEmail === newAssigneeEmail) return Promise.resolve();
    if (!newAssigneeEmail) return handleChangeAssignee(() => unassignTask(taskId));
    return handleChangeAssignee(() => assignTask(taskId, { assignee: newAssigneeEmail }));
  }, [handleChangeAssignee, task?.assigneeInfo?.assignee, taskId]);

  const handleCompleteTask = useCallback(async (formData: FormVariables) => {
    setSubmitError(undefined);
    try {
      if (!isAssigned) {
        await handleAssignToMe();
      }
      await completeTask(taskId, { variables: wrapVariables(formData) });
      void invalidateTasks();
      void invalidateTaskDetails(taskId);
      onComplete?.();
    } catch (e) {
      const errorInfo = extractErrorInfo(e);
      if (isMounted.current) setSubmitError(errorInfo.message);
    }
  }, [isAssigned, taskId, invalidateTasks, invalidateTaskDetails, onComplete, handleAssignToMe, isMounted]);

  const handleSubmitDraftVariables = useCallback(async (formData: FormVariables) => {
    setSubmitError(undefined);
    try {
      if (!isAssigned) {
        const currentUserEmail = user?.email;
        if (!currentUserEmail) throw new Error('No current user in context');
        await handleChangeAssignee(() => assignTask(taskId, { assignee: currentUserEmail }));
      }
      await saveDraftTaskVariables(taskId, wrapVariables(formData));
      await invalidateTaskDetails(taskId);
    } catch (e) {
      const errorInfo = extractErrorInfo(e);
      if (isMounted.current) setSubmitError(errorInfo.message);
    }
  }, [isAssigned, taskId, invalidateTaskDetails, user?.email, handleChangeAssignee, isMounted]);

  const handleSaveDraft = useCallback(async () => {
    // Ignore if no form loaded yet
    if (!loadedTaskForm) return;

    // Save draft with no data if loaded task has no form defined
    if (!loadedTaskForm.form) {
      await handleSubmitDraftVariables({});
      return;
    }

    // Save form state draft regardless of validation state
    const currentFormState = loadedTaskForm.form.submit().data;
    await handleSubmitDraftVariables(currentFormState);
  }, [loadedTaskForm, handleSubmitDraftVariables]);

  const handleSubmit = useCallback(async () => {
    // Ignore if no form loaded yet
    if (!loadedTaskForm) return;

    // Complete with no data if loaded task has no form defined
    if (!loadedTaskForm.form) {
      await handleCompleteTask({});
      return;
    }

    // Try submit form and complete task
    const submitResult = loadedTaskForm.form.submit();
    if (hasErrors(submitResult)) {
      setSubmitAttempted(true);
      setHasValidationErrors(true);
      return;
    }
    await handleCompleteTask(submitResult.data);
  }, [loadedTaskForm, handleCompleteTask]);

  const handleCommentsChanged = useCallback(async () => {
    await invalidateTaskDetails(taskId);
  }, [invalidateTaskDetails, taskId]);

  const toggleShowComments = useCallback(() => {
    setShowComments(prev => !prev);
  }, []);

  const handleFormEvent = useCallback((event: FormEvent) => {
    setHasValidationErrors(hasErrors(event));
  }, []);

  const resetLoadedTask = useCallback((form: Form | null = null) => {
    setSubmitAttempted(false);
    setSubmitError(undefined);
    setHasValidationErrors(false);
    setLoadedTaskForm({ form });
  }, []);

  return (
    <ContentPane
      fitParent={fitParent}
      header={task && (
        <div>
          <TaskHeaderTopContainer>
            <div>
              <TaskHeading>{task.name || 'Unnamed task'}</TaskHeading>
              <div>
                {task.creationDate ? toLongDateTime(task.creationDate) : '-'}
              </div>
              <div>
                <CamundaTaskState state={task.taskState} />
              </div>
            </div>
            <AssigneeContainer>
              <div>
                {isUpdatingAssignee && <SmallLoader />}
                {canChangeAssignee && !isAssignedToCurrentUser && !isUpdatingAssignee && (
                  <SlimSubmitButton onSubmit={handleAssignToMe} variant="tertiary">Assign to me</SlimSubmitButton>
                )}
                <DropdownControlled
                  label="Assignee"
                  disabled={!canChangeAssignee}
                  options={assigneeOptions}
                  value={currentAssigneeOption}
                  onChange={handleAssigneeSelectionChange}
                />
              </div>
              {updateAssigneeError && <FormJsErrorMessage>{updateAssigneeError}</FormJsErrorMessage>}
            </AssigneeContainer>
          </TaskHeaderTopContainer>
          <TaskHeaderContextActionsContainer>
            {activeContextActions?.map(a => (
              <SlimSubmitButton key={a.label} variant="secondary" startIcon={a.startIcon} onSubmit={a.onClick}>
                {a.label}
              </SlimSubmitButton>
            ))}
            <Button variant="secondary" startIcon={<Comment />} onClick={toggleShowComments}>
              {`${showComments ? 'Hide' : 'Show'} comments (${task.comments.length})`}
            </Button>
          </TaskHeaderContextActionsContainer>
        </div>
      )}
      footer={isOpen && (
        <ButtonsContainer>
          <div>
            {submitAttempted && hasValidationErrors && (
              <FormJsErrorMessage>One or more validation errors</FormJsErrorMessage>
            )}
            {submitError && <FormJsErrorMessage>{submitError}</FormJsErrorMessage>}
            {isAssignedToDifferentUser && (
              <div className="only-assigned-box">
                Can only be completed by assigned user.
                <div>
                  {isUpdatingAssignee
                    ? <SmallLoader />
                    : <SlimSubmitButton onSubmit={handleAssignToMe} variant="tertiary">Assign to me</SlimSubmitButton>}
                </div>
              </div>
            )}
          </div>
          <SubmitButton disabled={!canSubmit} inverted onSubmit={handleSaveDraft}>
            Save draft
          </SubmitButton>
          <SubmitButton disabled={!canSubmit} onSubmit={handleSubmit}>
            Complete task
          </SubmitButton>
        </ButtonsContainer>
      )}
      aside={showComments && task && <CamundaTaskComments task={task} onCommentsChanged={handleCommentsChanged} />}>
      {isLoading && <Loader />}
      {task && (
        <CamundaFormLoader
          processDefinitionKey={task.processDefinitionKey}
          formId={task.formId || ''}
          version={task.formVersion}
          data={variables}
          onFormChange={handleFormEvent}
          onFormMounted={resetLoadedTask}
          onEmptyFormSchema={resetLoadedTask}
        />
      )}
    </ContentPane>
  );
};
