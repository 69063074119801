export enum ProgressState {
  Loading = 'Loading',
  Success = 'Success',
  Warning = 'Warning',
  Error = 'Error'
}

export interface SignalREvent<T> {
  id: string;
  subject: string;
  timestamp: string;
  type: string;
  version: string;
  data: T;
}
