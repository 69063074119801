import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

const RowWrapper = styled.div<{ isExpanded?: boolean }>`
  display: ${props => props.isExpanded ? 'contents' : 'none'};
`;

const RowGradient = styled.div<{ direction: 'top' | 'bottom' }>`
  position: relative;
  grid-column: 1 / -1;
  &:after {
    content: '';
    position: absolute;
    height: 20px;
    width: 100%;
    ${props => props.direction === 'top' && css`
      top: 0;
      background: linear-gradient(180deg, rgba(0, 30, 60, 0.13) 0%, rgba(0, 30, 60, 0) 100%);
    `}
    ${props => props.direction === 'bottom' && css`
      bottom: 0;
      background: linear-gradient(0deg, rgba(0, 30, 60, 0.13) 0%, rgba(0, 30, 60, 0) 100%);
    `}
  }
`;

interface Props {
  isExpanded: boolean;
}
export const ExpandableRowWrapper: FC<PropsWithChildren<Props>> = ({ children, isExpanded }) => (
  <RowWrapper isExpanded={isExpanded}>
    <RowGradient direction="top" />
    {children}
    <RowGradient direction="bottom" />
  </RowWrapper>
);
