export const rowCells = {
  text: 'text',
  textTooltip: 'textTooltip',
  comment: 'comment',
  digitsTooltip: 'digitsTooltip',
  gap: 'gap',
  reactNode: 'reactNode',
  custom: 'custom',
  customWithProps: 'customWithProps'
} as const;

export const subrowCells = {
  gap: 'gap',
  text: 'text',
  textTooltip: 'textTooltip',
  digitsTooltip: 'digitsTooltip',
  comment: 'comment',
  custom: 'custom'
} as const;

export const tableCellVariant = {
  secondary: 'secondary',
  tertiary: 'tertiary'
} as const;

export type RowCellType = keyof typeof rowCells

export type SubrowCellType = keyof typeof subrowCells;

export type TableCellVariantType = keyof typeof tableCellVariant;
