import React, { ReactElement, useRef } from 'react';
import { Outlet } from 'react-router';
import styled from 'styled-components';
import { Header } from '../Header';
import { ColumnResizer } from '../layout/ColumnResizer';
import { useStoredState } from '../../hooks/useStoredState';

const MasterDetailsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${({ theme }) => theme.whiteBlue};

  > :first-child {
    flex: 0 0 auto;
  }

  > :last-child {
    min-height: 0;
    flex: 1 1 auto;
  }
`;

const MasterDetailsRow = styled.div<{
  resizable?: boolean;
  masterWidthPercentage?: number | null;
}>`
  display: grid;
  grid-template-columns: ${({ resizable, masterWidthPercentage }) => resizable
    ? `${masterWidthPercentage || 35}% 16px 1fr`
    : `fit-content(${masterWidthPercentage || 35}%) 1fr`};
  gap: ${({ resizable }) => resizable ? '0' : '16px'};
  padding: 16px 32px;
  align-items: stretch;

  > * {
    min-width: 0;
    min-height: 0;
    flex: 1 1 auto;

    &:first-child {
      ${({ resizable }) => resizable ? 'flex: 0 0 auto;' : ''}
    }
  }
`;

type MasterDetailRouteProps = {
  master: ReactElement;
  resizable?: boolean;
  masterWidthPercentage?: number;
  masterMinWidthPercentage?: number;
  masterMaxWidthPercentage?: number;
  masterWidthStorageKey?: string;
};

export const MasterDetailsPage = ({
  master,
  resizable,
  masterWidthPercentage,
  masterMinWidthPercentage,
  masterMaxWidthPercentage,
  masterWidthStorageKey
}: MasterDetailRouteProps) => {
  const [currentWithPercentage, setCurrentWidthPercentage] = useStoredState(masterWidthPercentage, masterWidthStorageKey);
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <MasterDetailsPageWrapper>
      <Header />
      <MasterDetailsRow resizable={resizable} ref={containerRef} masterWidthPercentage={currentWithPercentage}>
        {master}
        {resizable && (
          <ColumnResizer
            containerRef={containerRef}
            onResize={setCurrentWidthPercentage}
            minLeftPercentage={masterMinWidthPercentage}
            maxLeftPercentage={masterMaxWidthPercentage} />
        )}
        <Outlet />
      </MasterDetailsRow>
    </MasterDetailsPageWrapper>
  );
};
