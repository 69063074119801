import {
  ChangeObjectFlagRequest, InsuranceObject, changeObjectFlagAsync
} from '../../../services/objectsService';
import { FormValues } from './types';
import {
  getEmailRecipients, getOptionLabel, getOptionValue
} from '../../form/utils';
import { getDateOnlyString } from '../../../utils/date';
import { SelectedVessel } from '../../../utils/vessel';
import { OperationInstance } from '../../../hooks/useOperation';

const getChangeObjectFlagRequest = (values: FormValues, vessel: InsuranceObject): ChangeObjectFlagRequest => ({
  insuranceYear: vessel.insuranceYear,
  objectNumber: vessel.objectNumber,
  oldFlag: vessel.flag?.value ?? null,
  newFlag: getOptionValue(values.flag),
  effectiveDate: getDateOnlyString(values.effectiveDate),
  fleetName: vessel.fleetName,
  contractPeriodFrom: vessel.contractPeriodFrom,
  contractPeriodTo: vessel.contractPeriodTo,
  confirmationEmailRecipients: getEmailRecipients(values.emailRecipients)
});

const handleChangeVesselFlag = async (values: FormValues, vessel: InsuranceObject) => {
  const request = getChangeObjectFlagRequest(values, vessel);
  return changeObjectFlagAsync(request);
};

export const canSubmit = (values: FormValues, selected: SelectedVessel[]) =>
  !!(values.flag && values.effectiveDate && selected.length > 0);

export const createOperations = (
  vessels: InsuranceObject[],
  values: FormValues
) : OperationInstance[] =>
  vessels
    .map(vessel => ({
      instanceId: `${vessel.insuranceYear}-${vessel.objectNumber}`,
      title: `Changing vessel flag ${vessel.flag?.label} (${vessel.insuranceYear}) -> ${getOptionLabel(values.flag)}`,
      progressTopic: `/Objects/${vessel.insuranceYear}/${vessel.objectNumber}/FlagChange/*`,
      startRequest: () => handleChangeVesselFlag(values, vessel)
    }));
