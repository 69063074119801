import React, { FC, HTMLProps } from 'react';
import { TableSubrowSegment } from '../SortableTable/types';
import {
  TableCellText, TableCellTextTooltip, TableCellDigitsTooltip, TableCellMaxContent, TableCellComment
} from './TableCell';
import { useRowProps } from './TableRow/RowPropsProvider';

interface Props extends HTMLProps<HTMLSpanElement> {
  segment: TableSubrowSegment;
}
/**
 * Maps out a given RowSegment type to a corresponding cell component, based
 * on the provided `cellType`. Any properties to be propagated down to the cell
* can be provided via `restProps`
 */
export const TableSubrowCell: FC<Props> = ({ segment, ...restProps }) => {
  const { tooltipId, showFullComments } = useRowProps();
  const { value } = segment;

  if (segment.shouldSkip) {
    return null;
  }
  switch (segment.cellType) {
    case 'gap':
      return (
        <TableCellMaxContent {...restProps} span={segment.span} />
      );
    case 'text':
      return (
        <TableCellText {...restProps} value={value} />
      );
    case 'textTooltip':
      return (
        <TableCellTextTooltip {...restProps} value={value} tooltipId={tooltipId} />
      );
    case 'digitsTooltip':
      return (
        <TableCellDigitsTooltip {...restProps} value={value} tooltipId={tooltipId} />
      );
    case 'comment': {
      // Components disagree about type for "span" property, fix this here
      const { span, ...restPropsNoSpan } = restProps;
      return (
        <TableCellComment {...restPropsNoSpan} span={span?.toString()} comment={value} showFullComment={showFullComments} tooltipId={tooltipId} />
      );
    }
    case 'custom':
      return <>{value}</>;
    default:
      return null;
  }
};
