import React, { useState } from 'react';
import { PageLoader } from '@instech/components';
import { InsuranceObjectRequest, useObjects } from '../../../services/objectsService';
import { Page } from '../../layout/Page';
import { VesselSelector } from './VesselSelector';
import { useVesselSelection } from '../../../hooks/useVesselSelection';
import { ChangeFlagForm } from './ChangeFlagForm';
import { useFlags } from '../../../services/flagsService';

export const ChangeFlagPage = () => {
  const [vesselQuery, setVesselQuery] = useState<InsuranceObjectRequest | undefined>();
  const { data: vessels, isLoading } = useObjects(vesselQuery);
  const { data: flags } = useFlags();
  const { selectedVessels, selectVessel, resetVessels } = useVesselSelection(vessels ?? []);

  if (!flags) {
    return <PageLoader />;
  }

  const onComplete = () => {
    resetVessels();
    setVesselQuery(undefined);
  };
  return (
    <Page>
      <ChangeFlagForm vessels={vessels ?? []} setVessel={setVesselQuery} onComplete={onComplete} selectedVessels={selectedVessels} flags={flags} />
      <VesselSelector vessels={vessels ?? []} isLoading={isLoading} selectedVessels={selectedVessels} selectVessel={selectVessel} />
    </Page>
  );
};
