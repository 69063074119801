import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import {
  useLocation, useNavigate, useParams
} from 'react-router-dom';
import styled from 'styled-components';
import {
  DropdownControlled, ToggleControlled, WarningNotification
} from '@instech/components';
import { Filter } from '@instech/icons';
import { UserTask, useUserTasks } from '../../../services/userTasksService';
import { SortableTable } from '../../tables/SortableTable';
import { TableDefinition, TableHandlers } from '../../tables/tableDefinition';
import { toLongDateTime } from '../../../utils/date';
import { CamundaTaskState } from '../../camunda/CamundaTaskState';
import { ContentPane } from '../../layout/ContentPane';
import { variableFilters } from '../../../utils/userTasks';
import { useTasksFilter } from './useTasksFilter';
import { DebouncedTextFieldControlled } from '../../form/DebouncedTextFieldControlled';
import { MasterDetailsPage } from '../MasterDetailsPage';

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;

  > * {
    flex: 1 1 auto;
  }
`;

/**
 * Wrapper around ControlledDropdown that makes the select menu have higher z-index (default: 1),
 * to avoid conflict with SortableTables sticky header (z-index: 5) and Input icons (z-index: 10)
 * Component library does not expose any easy way of controlling the z-index.
 */
const DropdownWrapper = styled.div`
  > div > *:nth-child(2) > *:nth-child(4) {
    z-index: 15;
  }
`;

/**
 * Need to tweak the toggle slightly to fit neatly within the filter form
 */
const ToggleWrapper = styled.div`
  margin-top: -16px;

  &&& {
    input {
      margin-left: 0;
    }
  }
`;

/**
 * Wrapper around table to avoid rows appearing above table header when scrolling
 */
const TableWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > :first-child {
    flex: 0 0 auto;
  }

  > :last-child {
    flex: 1 1 auto;
    overflow: auto;
  }
`;

const createTableDefinition = (handlers: TableHandlers<UserTask>): TableDefinition<UserTask> => ({
  idSelector: t => t.id,
  handlers,
  columns: [
    {
      propName: 'name',
      title: 'Name',
      cellType: 'text',
      layoutWidth: '3fr',
      valueSelector: t => t.name,
      filterFacetSelector: t => t.name
    },
    {
      propName: 'process',
      title: 'Process',
      cellType: 'text',
      layoutWidth: '2fr',
      valueSelector: t => t.processName,
      filterFacetSelector: t => t.processName
    },
    {
      propName: 'created',
      title: 'Created',
      cellType: 'text',
      layoutWidth: 'fit-content(20%)',
      valueSelector: t => t.creationDate ? toLongDateTime(t.creationDate) : '-',
      sortBySelector: t => t.creationDate
    },
    {
      propName: 'status',
      title: 'Status',
      cellType: 'reactNode',
      layoutWidth: 'fit-content(20%)',
      valueSelector: t => <CamundaTaskState state={t.taskState} />,
      sortBySelector: t => t.taskState
    },
    {
      propName: 'assignee',
      title: 'Assigned To',
      cellType: 'text',
      maxContent: true,
      valueSelector: t => t.assigneeInfo ? t.assigneeInfo.name?.trim() || t.assigneeInfo.assignee : 'Unassigned',
      filterFacetSelector: t => t.assigneeInfo ? t.assigneeInfo.name?.trim() || t.assigneeInfo.assignee : 'Unassigned',
    },
  ]
});

export const UserTasksPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const {
    activeQuery,
    filters,
    setFilter,
    stateFilterOption,
    setStateFilterOption,
    stateFilterOptions,
    assigneeFilterOption,
    setAssigneeFilterOption,
    assigneeFilterOptions,
  } = useTasksFilter();
  const { data: tasks, isLoading, isValidating } = useUserTasks(activeQuery);

  const selectedIds = useMemo(() => params.userTaskId ? [params.userTaskId] : [], [params.userTaskId]);

  const openTask = useCallback((taskId: string) => navigate({ pathname: taskId, search: location.search }), [location.search, navigate]);
  const openTaskRef = useRef(openTask);
  openTaskRef.current = openTask;

  const incompleteResults = !!tasks && tasks.items.length === tasks.currentQuery.pageSize;

  const tableDefinition = useMemo(() => createTableDefinition({
    onRowClick: task => openTaskRef.current(task.id),
  }), []);

  const toggleShowAdvancedFilters = useCallback(() => setShowAdvancedFilters(prev => !prev), []);

  const resultsCounterLabelFactory = useCallback((originalCount: number, currentCount: number) => {
    const filteredResults = originalCount !== currentCount;
    const countPart = filteredResults || incompleteResults
      ? `${currentCount} of ${originalCount}${incompleteResults ? '+' : ''}`
      : `${currentCount}`;
    return `Showing ${countPart} tasks matching above search`;
  }, [incompleteResults]);

  useEffect(() => {
    if (params.userTaskId || !tasks?.items.length || isValidating) return;
    openTaskRef.current(tasks.items[0].id);
  }, [params.userTaskId, isValidating, tasks]);

  return (
    <ContentPane
      fitParent
      header={(
        <>
          <FiltersContainer>
            <DropdownWrapper>
              <DropdownControlled
                label="Status"
                options={stateFilterOptions}
                value={stateFilterOption}
                onChange={setStateFilterOption}
              />
            </DropdownWrapper>
            <DropdownWrapper>
              <DropdownControlled
                label="Assignee"
                options={assigneeFilterOptions}
                value={assigneeFilterOption}
                onChange={setAssigneeFilterOption}
              />
            </DropdownWrapper>
          </FiltersContainer>
          <ToggleWrapper>
            <ToggleControlled
              name="toggleAdvancedFilters"
              rightLabel="Show all advanced filters"
              label=""
              checked={showAdvancedFilters}
              onChange={toggleShowAdvancedFilters}
            />
          </ToggleWrapper>
          {variableFilters.filter(filter => showAdvancedFilters || !!filters[filter.queryKey]).map(filter => (
            <DebouncedTextFieldControlled
              key={filter.queryKey}
              name={filter.queryKey}
              endIcon={<Filter />}
              label={filter.inputLabel}
              onValueChange={newValue => setFilter(filter.queryKey, newValue)}
              value={filters[filter.queryKey] || ''}
            />
          ))}
        </>
      )}
    >
      {incompleteResults && (
        <WarningNotification
          headingText={`Results are limited to the ${tasks.currentQuery.pageSize} most recent tasks matching above search.`}
          size="small"
        />
      )}
      <TableWrapper>
        <SortableTable
          data={tasks?.items || []}
          selectedIds={selectedIds}
          isLoading={isLoading}
          tableDefinition={tableDefinition}
          sticky
          showResultsCounter
          resultsCounterLabelFactory={resultsCounterLabelFactory}
        />
      </TableWrapper>
    </ContentPane>
  );
};

export const UserTasksMasterDetailsPage = () => (
  <MasterDetailsPage
    resizable
    masterWidthPercentage={35}
    masterMinWidthPercentage={15}
    masterMaxWidthPercentage={85}
    masterWidthStorageKey="user-tasks-master-width"
    master={<UserTasksPage />} />
);
