import React from 'react';
import styled from 'styled-components';
import {
  ComplexTable, ComplexTableHeaders, Loader
} from '@instech/components';
import { SortableTableRows } from './Table/SortableTable/SortableTableRows';
import { useComplexTable } from './useSortableTable';
import { TableDefinition } from './tableDefinition';
import { SharedTooltip } from './Table/shared/Tooltip/SharedTooltip';

type SortableTableProps<TRow = any, TSubRow = any> = {
  data: TRow[];
  tableDefinition: TableDefinition<TRow, TSubRow>;
  selectedIds?: string[];
  isLoading?: boolean;
  sticky?: boolean;
  showResultsCounter?: boolean;
  resultsCounterLabelFactory?: (originalCount: number, currentCount: number) => string;
}

const defaultResultsCounterLabelFactory = (originalCount: number, currentCount: number) =>
  originalCount === currentCount
    ? `Showing ${currentCount} results`
    : `Showing ${currentCount} of ${originalCount} results`;

const ResultsCounter = styled.div`
  text-align: right;
  font-style: italic;
  color: ${({ theme }) => theme.placeholderGray};
`;

const CounterLoader = styled(Loader)`
  height: 18px;
  justify-content: flex-end;
`;

const InfoRow = styled.div<{ colSpan: number }>`
  text-align: center;
  font-style: italic;
  color: ${({ theme }) => theme.placeholderGray};
  padding: 8px;
  grid-column: 1 / span ${({ colSpan }) => colSpan};
`;

/**
 * Wrapper component around the table components stolen from ClaimsStatement
 */
export const SortableTable = <TRow, TSubRow>({
  data,
  tableDefinition,
  selectedIds,
  isLoading,
  sticky,
  showResultsCounter,
  resultsCounterLabelFactory = defaultResultsCounterLabelFactory
}: SortableTableProps<TRow, TSubRow>) => {
  const {
    table,
    sortedHeader,
    setSortedHeader,
    facetSelections,
    setFacetSelection
  } = useComplexTable(data, tableDefinition);

  return (
    <SharedTooltip id="table-tooltip">
      {showResultsCounter && (
        <ResultsCounter>
          {isLoading
            ? <CounterLoader />
            : resultsCounterLabelFactory(data.length, table.tableRows.length)}
        </ResultsCounter>
      )}
      <div>
        <ComplexTable layout={table.layout}>
          <ComplexTableHeaders
            allowFilters
            endpoint="placeholder"
            facets={table.facets}
            filterByFacets={setFacetSelection}
            filters={facetSelections}
            headers={table.header}
            sortedHeader={sortedHeader}
            setSortedHeader={setSortedHeader}
            stickyTop={sticky ? '0px' : undefined}
          />
          <SortableTableRows
            tableRows={table.tableRows}
            favourites={selectedIds}
            minSubrowsToExpand={table.minSubrowsToExpand}
          />
          {isLoading && <Loader />}
          {!isLoading && !table.tableRows.length && (
            <InfoRow colSpan={table.header.length}>No data to show</InfoRow>
          )}
        </ComplexTable>
      </div>
    </SharedTooltip>
  );
};
