import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { TableCell } from '@instech/components';
import { TableCellMaxContent } from './TableCellMaxContent';

type TextCellProps = ComponentProps<typeof TableCellMaxContent> & {
  underline?: boolean;
  maxContent?: boolean;
}
const TextCell = ({ maxContent, ...restProps }: TextCellProps) => maxContent
  ? <TableCellMaxContent {...restProps} />
  : <TableCell {...restProps} />;

export const StyledTextCell = styled(TextCell)<{ underline?: boolean }>`
  text-decoration: ${props => props.underline && 'underline'};
  ${props => !props.maxContent && 'text-overflow: ellipsis;'};
`;
