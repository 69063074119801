import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

const client = axios.create({
  baseURL: '/api/',
  timeout: 30000
});

client.interceptors.request.use(
  config => {
    if (config.headers) {
      config.headers.set('X-CSRF', '1');
    }
    return config;
  },
  error => Promise.reject(error)
);

export const addQueryParams = (endpoint: string, query: any) => {
  if (!query) return endpoint;
  const queryString = qs.stringify(query, { arrayFormat: 'repeat', format: 'RFC1738', skipNulls: true });
  return queryString ? `${endpoint}?${queryString}` : endpoint;
};

export const apiClient = client;

export const getJsonAsync = (endpoint: string) =>
  client.get(endpoint)
    .then(response => response.data);

export function postJsonAsync<T = any>(endpoint: string, payload: any) {
  return client.post<T>(endpoint, payload)
    .then<AxiosResponse<T> | null | undefined>(
      response => response,
      err => err?.response
    );
}

export function putJsonAsync<ReturnType = any>(endpoint: string, payload: any) {
  return client.put<ReturnType>(endpoint, payload).then(response => response.data);
}
