import React, {
  createContext, PropsWithChildren, useContext, useMemo
} from 'react';
import useSWR from 'swr';
import { useAccount } from './AuthenticationProvider';

interface FeatureFlags {
  enableFlagChange: boolean;
  enableClassChange: boolean;
  enableTasklist: boolean;
}

type FeatureNames = keyof FeatureFlags;

const featureFlagRoute = 'configuration/featureFlags';

const FeatureFlagContext = createContext({} as FeatureFlags);

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, } = useAccount();
  const { data } = useSWR<FeatureFlags>(isAuthenticated ? featureFlagRoute : null);

  const value = useMemo(() => data ?? {} as FeatureFlags, [data]);
  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlags = () => useContext<FeatureFlags>(FeatureFlagContext);

export const useFeatureFlag = (key: FeatureNames) => {
  const ctx = useFeatureFlags();
  return !!ctx[key];
};
