import { isAxiosError } from 'axios';

const isErrorObject = (error: unknown): error is { message: string; } =>
  !!error && !!(error as any).message && typeof (error as any).message === 'string';

type ErrorInfo = {
  message: string;
}
export const extractErrorInfo = (error: unknown): ErrorInfo => {
  if (isAxiosError(error)) {
    return { message: error.message };
  }
  if (isErrorObject(error)) {
    return { message: error.message };
  }
  if (typeof error === 'string') {
    return { message: error };
  }
  return { message: 'Request failed due to unkown error' };
};
