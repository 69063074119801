import {
  ChangeObjectClassRequest, InsuranceObject, changeObjectClassAsync
} from '../../../services/objectsService';
import { FormValues } from './types';
import {
  getEmailRecipients, getOptionLabel, getOptionValue
} from '../../form/utils';
import { getDateOnlyString } from '../../../utils/date';
import { SelectedVessel } from '../../../utils/vessel';
import { OperationInstance } from '../../../hooks/useOperation';

const getChangeObjectClassRequest = (values: FormValues, vessel: InsuranceObject): ChangeObjectClassRequest => ({
  insuranceYear: vessel.insuranceYear,
  objectNumber: vessel.objectNumber,
  oldClass: vessel.class?.value ?? null,
  newClass: getOptionValue(values.class),
  effectiveDate: getDateOnlyString(values.effectiveDate),
  fleetName: vessel.fleetName,
  contractPeriodFrom: vessel.contractPeriodFrom,
  contractPeriodTo: vessel.contractPeriodTo,
  confirmationEmailRecipients: getEmailRecipients(values.emailRecipients)
});

const handleChangeVesselClass = async (values: FormValues, vessel: InsuranceObject) => {
  const request = getChangeObjectClassRequest(values, vessel);
  return changeObjectClassAsync(request);
};

export const canSubmit = (values: FormValues, selected: SelectedVessel[]) =>
  !!(values.class && values.effectiveDate && selected.length > 0);

export const createOperations = (
  vessels: InsuranceObject[],
  values: FormValues
) : OperationInstance[] =>
  vessels
    .map(vessel => ({
      instanceId: `${vessel.insuranceYear}-${vessel.objectNumber}`,
      title: `Changing vessel class ${vessel.class?.label} (${vessel.insuranceYear}) -> ${getOptionLabel(values.class)}`,
      progressTopic: `/Objects/${vessel.insuranceYear}/${vessel.objectNumber}/ClassChange/*`,
      startRequest: () => handleChangeVesselClass(values, vessel)
    }));
