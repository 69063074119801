import React from 'react';
import { Route, Routes } from 'react-router';
import { RenameVesselPage } from './pages/RenameVesselPage';
import { Home } from './pages/Home';
import { routes } from './pages/routes';
import { ChangeFlagPage } from './pages/ChangeFlagPage';
import { useFeatureFlags } from '../providers/FeatureFlagProvider';
import { ChangeClassPage } from './pages/ChangeClassPage';
import { UserTasksMasterDetailsPage } from './pages/UserTasksPage';
import { UserTaskPage } from './pages/UserTaskPage';
import { NoSelectedUserTaskPage } from './pages/NoSelectedUserTaskPage';

export const AppRouting = () => {
  const { enableFlagChange, enableClassChange, enableTasklist } = useFeatureFlags();
  return (
    <Routes>
      <Route path={routes.renameVessel} element={<RenameVesselPage />} />
      {enableFlagChange && (
        <Route path={routes.changeFlag} element={<ChangeFlagPage />} />
      )}
      {enableClassChange && (
        <Route path={routes.changeClass} element={<ChangeClassPage />} />
      )}
      {enableTasklist && (
        <Route path={routes.tasklist.base} element={<UserTasksMasterDetailsPage />}>
          <Route path="" element={<NoSelectedUserTaskPage />} />
          <Route path={routes.tasklist.userTask} element={<UserTaskPage />} />
        </Route>
      )}
      <Route path="/" element={<Home />} />
    </Routes>
  );
};
