import { SortOrder, SortedTableHeader } from '@instech/components';
import { TableRow, TableRowData } from '../SortableTable/types';

interface SortDataReturn {
  data: TableRowData[];
  header?: SortedTableHeader;
}

// Set of values that should be sorted lower than actual values
const isNonValue = (val: any) => {
  if (val === null || val === undefined || val === '-') return true;
  return false;
};

// Find a 'sortBy' or 'segment[].key' in the row that matches the provided
// sortedHeader.propertyName, and return its corresponding value if it exists
const findParamInRow = (row?: TableRow, propName?: string) => {
  const sortByValue = row?.sortBy?.[propName || ''];
  // only discard actually undefined values, avoid nullish false-positives
  if (sortByValue !== undefined) return sortByValue;

  const foundSegment = row?.segments.find(segment => segment.key === propName);
  if (foundSegment) return foundSegment.value;

  return null;
};

const findSortParams = (itemA?: TableRow, itemB?: TableRow, sortedHeader?: SortedTableHeader) => {
  const paramA = findParamInRow(itemA, sortedHeader?.propertyName);
  const paramB = findParamInRow(itemB, sortedHeader?.propertyName);
  return [paramA, paramB];
};

/**
 * Compare two values and use a given SortOrder to determine
 * how the values should compare to one another in an Array.sort().
 */
export const compareValuesForSorting = (a: any, b: any, dir: SortOrder): number => {
  const sort = dir === SortOrder.Ascending ? 1 : -1;

  // Default output if working with non-values
  if (isNonValue(a) && isNonValue(b)) return sort * 0;
  if (isNonValue(a)) return sort * 1;
  if (isNonValue(b)) return sort * -1;

  // Apply sorting if both values are real
  if (a > b) return sort * 1;
  if (a < b) return sort * -1;
  return sort * 0;
};

/**
 * Sorting function built for the data structure used by the Collapsible Table components.
 * The sort will order the main row elements (does not yet support subrow sorting).
 */
export const sortTableData = (data: TableRowData[], sortedHeader?: SortedTableHeader): SortDataReturn => {
  if (!data || !sortedHeader) {
    return { data };
  }

  const sortedData = data;
  const { sortOrder } = sortedHeader;

  sortedData.sort((itemA, itemB) => {
    const [paramA, paramB] = findSortParams(itemA.row, itemB.row, sortedHeader);
    return compareValuesForSorting(paramA, paramB, sortOrder);
  });

  return { data: sortedData, header: sortedHeader };
};
