import { TableColumnHeader } from '@instech/components';
import {
  isColumnForMainRow, isColumnForSubRow, type ColumnDefinition, type TableDefinition
} from './tableDefinition';
import {
  SortableTableSchemaReturn,
  TableRow,
  TableRowData,
  TableRowSegment,
  TableSubrow,
  TableSubrowSegment
} from './Table/SortableTable/types';
import { arrayPartition } from './Table/utils/array';

type ProcessedTableDefinition<TMainRow> = {
  structure: Omit<SortableTableSchemaReturn, 'tableRows'> & { hasSubRows: boolean };
  tableRowsFactory: (data: TMainRow[]) => TableRowData[];
  tableFacetsFactory: (tableData: TableRowData[]) => { [propName: string]: Set<any> };
};

const getLayout = (columns: ColumnDefinition[], hasSubRows: boolean) => {
  if (!hasSubRows) return columns.map(c => c.layoutWidth ?? '1fr').join(' ');
  const [columnsPreSubRows, columnsPostSubRows] = arrayPartition(columns, c => !!c.beforeSubrows);
  return [...columnsPreSubRows, { layoutWidth: '76px' }, ...columnsPostSubRows].map(c => c.layoutWidth ?? '1fr').join(' ');
};

const toTableColumnHeader = (column: ColumnDefinition) => ({
  title: column.title,
  parameterName: column.propName,
  propertyName: column.propName
});

const createHeaderSegments = (table: TableDefinition<any, any>): TableColumnHeader[] => {
  if (!table.subRowsSelector) return table.columns.map(toTableColumnHeader);

  const [beforeSubrows, afterSubRows] = arrayPartition(table.columns, c => !!c.beforeSubrows);
  return [
    ...beforeSubrows.map(toTableColumnHeader),
    {
      title: table.subRowsHeaderText || '',
      propertyName: 'subrowsCount',
      parameterName: 'subrowsCount',
    },
    ...afterSubRows.map(toTableColumnHeader)
  ];
};

const createTableRowFactory = <TMainRow, TSubRow>({ idSelector, isFadedSelector, columns, handlers }: TableDefinition<TMainRow, TSubRow>) => {
  const sortBySetups = columns
    .filter(isColumnForMainRow)
    .map(c => {
      const sortBySelector = c.usage === 'both' ? c.mainSortBySelector || c.sortBySelector : c.sortBySelector;
      return sortBySelector ? { propName: c.propName, sortBySelector } : null;
    })
    .filter((c): c is NonNullable<typeof c> => !!c);

  const sortByFactory = (item: TMainRow) => sortBySetups.reduce((res, { propName, sortBySelector }) => ({ ...res, [propName]: sortBySelector(item) }), {});

  const facetsSetups = columns
    .filter(isColumnForMainRow)
    .map(c => {
      const facetSelector = c.usage === 'both' ? c.mainFilterFacetSelector || c.filterFacetSelector : c.filterFacetSelector;
      return facetSelector ? { propName: c.propName, facetSelector } : null;
    })
    .filter((c): c is NonNullable<typeof c> => !!c);
  const facetsFactory = (item: TMainRow) => facetsSetups.reduce((res, { propName, facetSelector }) => ({ ...res, [propName]: facetSelector(item) }), {});

  const segmentFactories = columns.map<(rowData: TMainRow) => TableRowSegment>(c => {
    if (!isColumnForMainRow(c)) {
      return () => ({
        key: c.propName,
        cellType: 'gap',
        beforeSubrows: c.beforeSubrows
      });
    }
    const valueSelector = c.usage === 'both' ? c.mainValueSelector ?? c.valueSelector : c.valueSelector;
    if (!valueSelector) throw new Error(`No value selector given for main for for column with propName ${c.propName}`);
    return item => ({
      key: c.propName,
      cellType: c.cellType ?? 'text',
      value: valueSelector(item),
      beforeSubrows: c.beforeSubrows,
      maxContent: c.maxContent,
    });
  });

  const onRowClick = handlers?.onRowClick;

  return (rowData: TMainRow): TableRow => ({
    id: idSelector(rowData),
    onClick: onRowClick ? () => onRowClick(rowData) : undefined,
    sortBy: sortByFactory(rowData),
    facets: facetsFactory(rowData),
    isFaded: isFadedSelector?.(rowData),
    segments: segmentFactories.map(segmentFactory => segmentFactory(rowData))
  });
};

const createTableSubRowFactory = <TMainRow, TSubRow>({
  columns,
  subRowIdSelector,
  subRowsSelector,
  handlers
}: TableDefinition<TMainRow, TSubRow>): (subRow: TSubRow) => TableSubrow => {
  if (!subRowsSelector) {
    return () => {
      throw new Error('No subRowsSelector is given');
    };
  }
  if (!subRowIdSelector) throw new Error('Need subRowIdSelector if subRowsSelector is given');

  const subRowSegmentFactories = columns.map<(item: TSubRow) => TableSubrowSegment>(c => {
    if (!isColumnForSubRow(c)) {
      return () => ({
        key: c.propName,
        cellType: 'gap',
        beforeSubrows: c.beforeSubrows
      });
    }
    const valueSelector = c.usage === 'both' ? c.subValueSelector ?? c.valueSelector : c.valueSelector;
    if (!valueSelector) throw new Error(`No value selector given for sub row for column with propName ${c.propName}`);
    return item => ({
      key: c.propName,
      cellType: c.cellType ?? 'text',
      value: valueSelector(item),
      beforeSubrows: c.beforeSubrows
    });
  });

  const onSubRowClick = handlers?.onSubRowClick;

  return (subRow: TSubRow): TableSubrow => ({
    id: subRowIdSelector(subRow),
    onClick: onSubRowClick ? () => onSubRowClick(subRow) : undefined,
    segments: subRowSegmentFactories.map(segmentFactory => segmentFactory(subRow))
  });
};

const createTableRowsFactory = <TMainRow, TSubRow>(
  tableDefinition: TableDefinition<TMainRow, TSubRow>
) => {
  const tableRowFactory = createTableRowFactory(tableDefinition);
  const tableSubRowFactory = createTableSubRowFactory(tableDefinition);
  return (data: TMainRow[]) =>
    data.map<TableRowData>(item => {
      const subrows = tableDefinition.subRowsSelector?.(item);
      const mainRow = tableRowFactory(item);
      return {
        row: subrows ? { ...mainRow, sortBy: { ...mainRow.sortBy, subrowsCount: subrows.length } } : mainRow,
        subrows: subrows?.map(tableSubRowFactory) || []
      };
    });
};

const createTableFacetsFactory = () => (tableData: TableRowData[]) => {
  const facets: { [propName: string]: Set<any> } = {};
  tableData.forEach(tableRowData => {
    if (tableRowData.row) {
      Object.entries(tableRowData.row.facets).forEach((([propName, facetValue]) => {
        let facetValues = facets[propName];
        if (!facetValues) {
          facetValues = new Set();
          facets[propName] = facetValues;
        }
        facetValues.add(facetValue);
      }));
    }
  });
  return facets;
};

export const processTableDefinition = <TMainRow, TSubRow>(tableDefinition: TableDefinition<TMainRow, TSubRow>): ProcessedTableDefinition<TMainRow> => ({
  structure: {
    layout: getLayout(tableDefinition.columns, !!tableDefinition.subRowsSelector),
    header: createHeaderSegments(tableDefinition),
    // minSubrowsToExpand is only used when subRowsSelector is given, and then ut should always be 1 or higher
    minSubrowsToExpand: tableDefinition.subRowsSelector ? tableDefinition.minSubrowsToExpand || 1 : undefined,
    hasSubRows: !!tableDefinition.subRowsSelector
  },
  tableRowsFactory: createTableRowsFactory(tableDefinition),
  tableFacetsFactory: createTableFacetsFactory(),
});
