import { useCallback } from 'react';
import { useModalContext } from './ModalContext';
import { ConfirmModal, ConfirmModalProps } from './ConfirmModal';

type ConfirmModalParams = Omit<ConfirmModalProps, 'onConfirm' | 'onCancel'> & {
  title: string;
}

export const useConfirmModal = () => {
  const { open, close } = useModalContext();

  const requestUserConfirmation = useCallback(async ({ title, ...dialogParams }: ConfirmModalParams) => {
    const confirmResult = await new Promise<boolean>((resolve, reject) => {
      open({
        component: ConfirmModal,
        options: { title, size: 'medium' },
        args: {
          ...dialogParams,
          onConfirm: () => resolve(true),
          onCancel: () => resolve(false)
        },
      });
    });
    close();
    return confirmResult;
  }, [open, close]);

  return { requestConfirmation: requestUserConfirmation };
};
