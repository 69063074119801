import React, { FC, HTMLProps } from 'react';
import { StyledTextCell } from './Components';
import { textOrDash } from '../../utils/text';

interface Props extends Omit<HTMLProps<HTMLSpanElement>, 'value'> {
  value: string | null;
  underline?: boolean;
  maxContent?: boolean;
}
export const TableCellText: FC<Props> = ({ value, underline, ...props }) => (
  <StyledTextCell {...props}>
    <span>{textOrDash(value)}</span>
  </StyledTextCell>
);
