import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

export const StyledTooltip = styled(Tooltip)`
  &.ics-tooltip {
    font-size: 14px;
    line-height: 16px;
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.24));
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.marineBlue};
    width: auto;
    max-width: 512px;
    word-break: break-word;
    white-space: pre-wrap;
    &.show {
      opacity: 1; 
    }
    display: flex;
    &.tooltip { &::after { left: 82% !important; } } 
    @media screen and (max-width: 660px) {
      max-width: 70vw;
    }
  } 
`;
