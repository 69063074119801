import { useCallback, useMemo } from 'react';
import {
  TaskState, UserTasksQuery, useTasklistUserGroups, useTasklistUsers
} from '../../../services/userTasksService';
import { useTasksQueryParams } from '../../../hooks/useTasksQueryParams';
import { variableFilters } from '../../../utils/userTasks';
import { useAccount } from '../../../providers/AuthenticationProvider';

type DropdownOption<T extends string> = { label: string; value: T };

type StateFilter = TaskState | '';
const openStateFilter: DropdownOption<StateFilter> = {
  label: 'Open',
  value: 'Created'
};

const stateFilterOptions: DropdownOption<StateFilter>[] = [
  {
    label: 'All states',
    value: ''
  },
  openStateFilter,
  {
    label: 'Closed',
    value: 'Completed'
  },
  {
    label: 'Cancelled',
    value: 'Canceled'
  }
];

type AssigneeFilter = '' | 'me' | 'unassigned' | 'assigned' | string;
const assignedToMeFilter: DropdownOption<AssigneeFilter> = {
  label: 'Me',
  value: 'me'
};
const staticAssigneeFilterOptions: DropdownOption<AssigneeFilter>[] = [
  {
    label: 'Any',
    value: ''
  },
  assignedToMeFilter,
  {
    label: 'Unassigned',
    value: 'unassigned'
  },
  {
    label: 'Assigned',
    value: 'assigned'
  },
];

export const useTasksFilter = () => {
  const { user } = useAccount();

  const { filters, setFilter } = useTasksQueryParams();
  const { data: tasklistUsers } = useTasklistUsers();
  const { data: tasklistUserGroups } = useTasklistUserGroups();

  const assigneeFilterOptions = useMemo(() => {
    const userFilterOptions = tasklistUsers?.items.map(u => ({
      label: u.name?.trim() || u.email,
      value: u.email
    })) || [];
    const groupFilterOptions = tasklistUserGroups?.items.map(g => ({
      label: g.name,
      value: g.id
    })) || [];
    return [...staticAssigneeFilterOptions, ...userFilterOptions, ...groupFilterOptions];
  }, [tasklistUsers, tasklistUserGroups]);

  const stateFilterOption = useMemo(() => stateFilterOptions.find(o => o.value === filters.state), [filters]);
  const assigneeFilterOption = useMemo(
    () => assigneeFilterOptions.find(o => o.value === filters.assignee),
    [assigneeFilterOptions, filters.assignee]);

  const activeQuery = useMemo(() => {
    let query: UserTasksQuery = { pageSize: 1000 };
    if (stateFilterOption?.value) {
      query = { ...query, state: stateFilterOption.value };
    }
    if (assigneeFilterOption?.value) {
      switch (assigneeFilterOption?.value) {
        case 'me':
          if (user?.email) {
            query = { ...query, assignees: [user?.email] };
          }
          break;
        case 'assigned':
          query = { ...query, assigned: true };
          break;
        case 'unassigned':
          query = { ...query, assigned: false };
          break;
        case '':
          break;
        default:
          query = { ...query, assignees: [assigneeFilterOption.value] };
          break;
      }
    }
    const activeVariableFilters = variableFilters.filter(f => filters[f.queryKey]);
    if (activeVariableFilters.length) {
      query = {
        ...query,
        taskVariables: activeVariableFilters
          .reduce<{ [name: string]: string }>((prev, f) => ({ ...prev, [f.variableName]: JSON.stringify(filters[f.queryKey] || '') }), {})
      };
    }
    return query;
  }, [assigneeFilterOption?.value, filters, stateFilterOption?.value, user?.email]);

  const setStateFilterOption = useCallback((option: DropdownOption<StateFilter> | undefined) => setFilter('state', option?.value), [setFilter]);
  const setAssigneeFilterOption = useCallback((option: DropdownOption<AssigneeFilter> | undefined) => setFilter('assignee', option?.value), [setFilter]);

  return {
    activeQuery,
    setFilter,
    filters,
    stateFilterOption,
    setStateFilterOption,
    stateFilterOptions,
    assigneeFilterOption,
    setAssigneeFilterOption,
    assigneeFilterOptions
  };
};
