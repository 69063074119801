import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { TableCell } from '@instech/components';
import { DecimalNumberField } from '../../shared/DecimalNumberField';

const InputTableCellContainer = styled(TableCell)`
  padding-top: 8px;
  padding-bottom: 8px;

  > div {
    max-width: 48ch;
  }
`;

type DecimalNumberProps = ComponentProps<typeof DecimalNumberField>;
const DecimalNumberCellField = (props: DecimalNumberProps) => <DecimalNumberField noLabel noErrors {...props} />;

type InputTableCellProps = {
  inputProps: DecimalNumberProps;
}
export const TableCellInput = ({ inputProps }: InputTableCellProps) => (
  <InputTableCellContainer>
    <DecimalNumberCellField {...inputProps} />
  </InputTableCellContainer>
);
