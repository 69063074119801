import React from 'react';
import styled from 'styled-components';
import {
  Cancelled,
  Approved,
  Warning,
  Assignment,
  Question
} from '@instech/icons';
import { TaskState } from '../../services/userTasksService';

const Wrapper = styled.span`
  display: inline-flex;
  gap: 8px;
  align-items: center;
`;

const Icon = ({ state }: { state: TaskState }) => {
  switch (state) {
    case 'Created':
      return <Assignment />;
    case 'Completed':
      return <Approved />;
    case 'Failed':
      return <Warning />;
    case 'Canceled':
      return <Cancelled />;
    default:
      return <Question />;
  }
};

const Label = ({ state }: { state: TaskState }) => {
  switch (state) {
    case 'Created':
      return <>Open</>;
    case 'Completed':
      return <>Closed</>;
    case 'Canceled':
      return <>Cancelled</>;
    default:
      return <>{state}</>;
  }
};

export const CamundaTaskState = ({ state }: { state: TaskState }) => (
  <Wrapper>
    <Icon state={state} />
    <Label state={state} />
  </Wrapper>
);
