import React, { useState } from 'react';
import { InsuranceObjectRequest, useObjects } from '../../../services/objectsService';
import { Page } from '../../layout/Page';
import { VesselSelector } from './VesselSelector';
import { RenameVesselForm } from './RenameVesselForm';
import { useVesselSelection } from '../../../hooks/useVesselSelection';

export const RenameVesselPage = () => {
  const [vesselQuery, setVesselQuery] = useState<InsuranceObjectRequest | undefined>();
  const { data: vessels, isLoading } = useObjects(vesselQuery);
  const { selectedVessels, selectVessel, resetVessels } = useVesselSelection(vessels ?? []);

  const onComplete = () => {
    resetVessels();
    setVesselQuery(undefined);
  };
  return (
    <Page>
      <RenameVesselForm vessels={vessels ?? []} setVessel={setVesselQuery} onComplete={onComplete} selectedVessels={selectedVessels} />
      <VesselSelector vessels={vessels ?? []} isLoading={isLoading} selectedVessels={selectedVessels} selectVessel={selectVessel} />
    </Page>
  );
};
