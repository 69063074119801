import React, {
  ComponentProps, createContext, FunctionComponent, ReactNode, useContext
} from 'react';
import { useTheme } from 'styled-components';
import { StyledTooltip } from './Components';

interface TooltipContextData {
  tooltipId: string;
}
const SharedTooltipContext = createContext<TooltipContextData | null>(null);

type SharedTooltipProps = ComponentProps<typeof StyledTooltip> & {
  id: string;
  children: ReactNode;
}
/**
 * Create a ReactTooltip instance that can be shared by tooltip anchors within its context.
 * The SharedTooltip component instantiates an `id` for the tooltip, which can then be
 * retrieved as `tooltipId` with the `useSharedTooltip()` context hook.
 *
 * The tooltip is then anchored to a component by pointing `data-tooltip-id={tooltipId}` and
 * `data-tip="Tooltip text"` to a suitable HTML element (div, span, etc.). For example:
 *
 * `<div data-tooltip-id={tooltipId} data-tip="Hello world">Text</div>`
 *
 * If the tooltip needs to contain HTML, by setting `html` on the anchor and providing an
 * HTML string as the `data-tip`, you can sanitize the contents manually by using the
 * `@/utils/useSanitizer` helper function.
 */
export const SharedTooltip: FunctionComponent<SharedTooltipProps> = ({ children, id, ...props }) => {
  const theme = useTheme();
  const value = React.useMemo(() => ({
    tooltipId: id
  }), [id]);
  return (
    <SharedTooltipContext.Provider value={value}>
      <StyledTooltip
        id={id}
        className="ics-tooltip"
        variant="info"
        float
        arrowColor={theme.marineBlue}
        {...props}
      />
      {children}
    </SharedTooltipContext.Provider>
  );
};

export const useSharedTooltip = () => {
  const tooltipContext = useContext(SharedTooltipContext);
  if (!tooltipContext) throw new Error('No SharedTooltipContext has been provided where useSharedTooltip was used');
  return tooltipContext;
};
