import React, { FC } from 'react';
import styled from 'styled-components';
import { TableCell, TableCellProps } from '@instech/components';
import {
  textOrNone, ellipsisOrDash, textOrDash
} from '../../utils/text';
import { TableCellMaxContent } from './TableCellMaxContent';
import { TableCellVariantType } from '../../types';

interface Props extends TableCellProps {
  comment: string | null | undefined;
  showFullComment?: boolean;
  isFinished?: boolean;
  cellVariant?: TableCellVariantType;
  tooltipId?: string;
}

const FullCommentCell = styled(TableCell)`
  width: 500px;
  white-space: pre-wrap;
`;

export const TableCellComment: FC<Props> = ({
  comment,
  showFullComment,
  tooltipId,
  ...props
}) => {
  if (showFullComment) {
    return (
      <FullCommentCell {...props}>
        <span>
          {textOrDash(comment)}
        </span>
      </FullCommentCell>
    );
  }

  return (
    <TableCellMaxContent {...props}>
      <span data-tooltip-id={tooltipId} data-tip={textOrNone(comment)}>
        {ellipsisOrDash(comment)}
      </span>
    </TableCellMaxContent>
  );
};
