import { Loader } from '@instech/components';
import {
  Check, Cry, Warning
} from '@instech/icons';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const DefaultErrorWrapper = styled.div`
  margin: 1em 0;
  display: flex;
  gap: 1em;
  align-items: center;
`;

const LargeErrorWrapper = styled.div`
  text-align: center;
  margin: 0;
`;

const TextWrapper = styled.div<{ large?: boolean }>`
  font-weight: bold;
  margin: ${({ large }) => large ? '30px' : '0'};
`;

const IconWrapper = styled.div<{ large?: boolean }>`
  display: flex;
  justify-content: center;
  & svg {
    height: ${({ large }) => large ? '70px' : '35px'};
    width: ${({ large }) => large ? '70px' : '35px'};
  }
`;

const SuccessIconWrapper = styled(IconWrapper)`
  & svg {
    color: ${({ theme }) => theme.green};
  }
`;

const WarningIconWrapper = styled(IconWrapper)`
  & svg {
    color: ${({ theme }) => theme.darkYellow};
  }
`;

const ErrorIconWrapper = styled(IconWrapper)`
  & svg {
    color: ${({ theme }) => theme.marineBlue};
  }
`;

const SuccessIcon = ({ large }: { large?: boolean }) => (
  <SuccessIconWrapper large={large}>
    <Check />
  </SuccessIconWrapper>
);

const WarningIcon = ({ large }: { large?: boolean }) => (
  <WarningIconWrapper large={large}>
    <Warning />
  </WarningIconWrapper>
);

const ErrorIcon = ({ large }: { large?: boolean }) => (
  <ErrorIconWrapper large={large}>
    <Cry />
  </ErrorIconWrapper>
);

type MessageType = 'loading' | 'success' | 'error' | 'warning';

const MessageIcon = ({ type, large }: { type: MessageType, large?: boolean }) => {
  switch (type) {
    case 'error':
      return <ErrorIcon large={large} />;
    case 'warning':
      return <WarningIcon large={large} />;
    case 'success':
      return <SuccessIcon large={large} />;
    case 'loading':
      return <Loader size={large ? 'large' : 'medium'} />;
    default:
      return null;
  }
};

const MessageWrapper = ({ large, children }: { large?: boolean, children: ReactNode }) => large
  ? <LargeErrorWrapper>{children}</LargeErrorWrapper>
  : <DefaultErrorWrapper>{children}</DefaultErrorWrapper>;

export const Message = ({
  message,
  details,
  large,
  type = 'success'
}: {
  message: string | null | undefined;
  details?: string | null;
  large?: boolean;
  type?: MessageType;
}) => (
  <MessageWrapper large={large}>
    <MessageIcon large={large} type={type} />
    <TextWrapper large={large}>
      <p>{message}</p>
      {details && <p>{details}</p>}
    </TextWrapper>
  </MessageWrapper>
);
