import { SortedTableHeader } from '@instech/components';
import {
  useCallback, useMemo, useState
} from 'react';
import type { TableDefinition } from './tableDefinition';
import { Table, useTable } from './useTable';
import { sortTableData } from './Table/utils/sorting';

const useTableFiltering = (table: Table) => {
  const [facetSelections, setFacetSelections] = useState<{[propName: string]: string[]}>({});

  const setFacetSelection = useCallback(
    (propName: string, selectedValues: string[]) => setFacetSelections(prev => ({ ...prev, [propName]: selectedValues })),
    []);

  const filteredTable = useMemo(() => {
    const activeFacetFilters = Object.entries(facetSelections).filter(([_, values]) => values.length);
    if (activeFacetFilters.length === 0) return table;
    const filteredTableRows = table.tableRows
      .filter(row => activeFacetFilters
        .every((([facet, values]) => values
          .some(value => row.row.facets?.[facet] === value))));
    return { ...table, tableRows: filteredTableRows };
  }, [table, facetSelections]);

  return { filteredTable, facetSelections, setFacetSelection };
};

const useTableSorting = (table: Table) => {
  const [sortedHeader, setSortedHeader] = useState<SortedTableHeader>();

  const sortedTable = useMemo(() => ({
    ...table, tableRows: sortTableData(table.tableRows, sortedHeader).data
  }), [table, sortedHeader]);

  return {
    sortedTable,
    sortedHeader,
    setSortedHeader
  };
};

export const useComplexTable = <TRow = any, TSubRow = any>(data: TRow[], tableDefinition: TableDefinition<TRow, TSubRow>) => {
  const basicTable = useTable(data, tableDefinition);

  const { filteredTable, facetSelections, setFacetSelection } = useTableFiltering(basicTable);

  const { sortedTable, sortedHeader, setSortedHeader } = useTableSorting(filteredTable);

  return {
    table: sortedTable,
    sortedHeader,
    setSortedHeader,
    facetSelections,
    setFacetSelection
  };
};
