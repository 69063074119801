import { ReactNode } from 'react';
import { RowCellType, SubrowCellType } from './Table/types';

export type ColumnDefinition = {
  title: string;
  layoutWidth?: string;
  cellType?: RowCellType;
  beforeSubrows?: boolean;
  maxContent?: boolean;
  propName: string;
}

export type MainOnlyColumnDefinition<TMainRow = any> = ColumnDefinition & {
  usage?: 'main' | undefined;
  cellType?: RowCellType;
  sortBySelector?: (item: TMainRow) => any;
  valueSelector: (row: TMainRow) => ReactNode;
  filterFacetSelector?: (row: TMainRow) => ReactNode;
}

export type BothColumnDefinition<TMainRow = any, TSubRow = any> = ColumnDefinition & {
  usage: 'both';
  cellType?: RowCellType & SubrowCellType;
  sortBySelector?: (item: TMainRow) => any;
  mainCellType?: RowCellType;
  subCellType?: SubrowCellType;
  valueSelector?: (row: TMainRow | TSubRow) => ReactNode;
  mainSortBySelector?: (item: TMainRow) => any;
  subSortBySelector?: (item: TSubRow) => any;
  mainValueSelector?: (item: TMainRow) => any;
  subValueSelector?: (item: TSubRow) => any;
  filterFacetSelector?: (row: TMainRow | TSubRow) => any;
  mainFilterFacetSelector?: (item: TMainRow) => any;
  subFilterFacetSelector?: (item: TSubRow) => any;
}

export type SubOnlyColumnDefinition<TSubRow = any> = ColumnDefinition & {
  usage: 'sub';
  cellType?: SubrowCellType;
  valueSelector: (row: TSubRow) => ReactNode;
  filterFacetSelector?: (row: TSubRow) => ReactNode;
}

export type MainColumnDefinition<TMainRow = any, TSubRow = any> =
  MainOnlyColumnDefinition<TMainRow> |
  BothColumnDefinition<TMainRow, TSubRow>;

export type SubColumnDefinition<TMainRow = any, TSubRow = any> =
  BothColumnDefinition<TMainRow, TSubRow> |
  SubOnlyColumnDefinition<TSubRow>;

export type AnyColumnDefinition<TMainRow = any, TSubRow = any> =
  MainOnlyColumnDefinition<TMainRow> |
  BothColumnDefinition<TMainRow, TSubRow> |
  SubOnlyColumnDefinition<TSubRow>;

export type TableHandlers<TMainRow = any, TSubRow = any> = {
  onRowClick?: (item: TMainRow) => void;
  onSubRowClick?: (item: TSubRow) => void;
}

export type TableDefinition<TRow = any, TSubRow = any> = {
  idSelector: (item: TRow) => string;
  columns: AnyColumnDefinition<TRow, TSubRow>[];
  isFadedSelector?: (item: TRow) => boolean;
  minSubrowsToExpand?: number;
  handlers?: TableHandlers<TRow, TSubRow>
  subRowsSelector?: (item: TRow) => TSubRow[];
  subRowIdSelector?: (item: TSubRow) => string;
  subRowsHeaderText?: string;
}

export const isColumnForMainRow = <TMainRow = any, TSubRow = any>(
  column: AnyColumnDefinition<TMainRow, TSubRow>
): column is MainColumnDefinition<TMainRow, TSubRow> => column.usage === 'both' || column.usage === 'main' || !column.usage;

export const isColumnForSubRow = <TMainRow = any, TSubRow = any>(
  column: AnyColumnDefinition<TMainRow, TSubRow>
): column is SubColumnDefinition<TMainRow, TSubRow> => column.usage === 'both' || column.usage === 'sub';
