import React, { FC } from 'react';
import { ExpandableTableRow, ExpandedCellButton } from '../ExpandableTableRow';
import { TableSubrow, TableSubrowSegment } from './types';
import { TableSubrowCell } from '../core/TableSubrowCell';
import { arrayPartition } from '../utils/array';

const createCellProps = (subrow: TableSubrow, segment: TableSubrowSegment) => {
  const mapKey = `${subrow.id}-${segment.key}`;
  const props = {
    right: segment.align === 'right'
  };
  return { mapKey, props };
};

interface Props {
  subrow: TableSubrow;
  even: boolean;
}
/**
 * Displays a  set of inner table rows for the CH invoices table.
 * It renders out a row for each split in the adjusterSplits, and handles whether
 * to show based on a flag set in the component that imports this one.
 */
export const SortableTableSubrow: FC<Props> = ({ subrow, even }) => {
  // If any columns should render before the subrow expand, partition the segments now
  const [beforeSubrowColumn, afterSubrowColumn] = arrayPartition(subrow.segments, elem => !!elem.beforeSubrows);
  return (
    <ExpandableTableRow even={even}>
      {beforeSubrowColumn.map(segment => {
        const { mapKey, props } = createCellProps(subrow, segment);
        return <TableSubrowCell key={mapKey} segment={segment} {...props} />;
      })}
      <ExpandedCellButton />
      {afterSubrowColumn.map(segment => {
        const { mapKey, props } = createCellProps(subrow, segment);
        return <TableSubrowCell key={mapKey} segment={segment} {...props} />;
      })}
    </ExpandableTableRow>
  );
};
