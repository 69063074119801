import { InsuranceObject } from '../services/objectsService';

export interface SelectedVessel {
  id: string;
  // It is used to cover scenarios where multiple vessels with same id will be selected, but only one request is sent for the clicked one
  isClicked: boolean;
}

export const constructId = (
  id: string,
  fleetName: string,
  contractPeriodFrom: string | null,
  contractPeriodTo: string | null
): string => {
  const from = contractPeriodFrom ? `|${contractPeriodFrom}` : '';
  const to = contractPeriodTo ? `|${contractPeriodTo}` : '';

  return `${id}|${fleetName}${from}${to}`;
};

export const deconstructId = (id: string) => id.split('|')[0];

export const getSelectedVesselObjects = (vessels: InsuranceObject[], selectedVessels: SelectedVessel[]): InsuranceObject[] =>
  vessels.filter(vessel =>
    selectedVessels.some(v => {
      const parts = v.id.split('|');
      const [id, fleetName, contractPeriodFrom, contractPeriodTo] = parts;

      let result = vessel.id === id && vessel.fleetName === fleetName;
      if (result && parts.length > 2) {
        result = vessel.contractPeriodFrom === contractPeriodFrom && vessel.contractPeriodTo === contractPeriodTo;
      }

      return result;
    })
  );
