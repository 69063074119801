import React, {
  createContext, FC, PropsWithChildren, useContext, useMemo
} from 'react';

interface RowContextData<T> {
  tooltipId: string;
  showFullComments?: boolean;
  customProps?: T;
}

const RowPropsContext = createContext<RowContextData<any> | null>(null);

export const RowPropsProvider: FC<PropsWithChildren<RowContextData<any>>> = ({
  children,
  tooltipId = '',
  showFullComments = false,
  customProps = {}
}) => {
  const value = useMemo(() => ({
    tooltipId, showFullComments, customProps
  }), [showFullComments, tooltipId, customProps]);
  return (
    <RowPropsContext.Provider value={value}>
      {children}
    </RowPropsContext.Provider>
  );
};

/**
 * Provides access to particular props provided to a Row component, down to
 * the individual cell components inside its child row or subrows.
 *
 * This can be particularly useful for custom cell components, to access
 * higher-level properties like the table's Tooltip ID or display flags,
 * without having to propagate it down to the cells via schema generation.
 */
export function useRowProps<T>() {
  return useContext(RowPropsContext) as RowContextData<T>;
}
