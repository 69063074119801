import { useMemo, useRef } from 'react';

/**
 * useMemo wrapper on an dependency array that will return the old value
 * unless the given is equivalent predicate evaluates to false
 */
const usePredicateDepsMemo = <TDeps extends readonly any[]>(
  isEquivalentPredicate: (prevDeps: TDeps) => boolean,
  newDeps: TDeps
) => {
  const prevDeps = useRef<TDeps>();

  return useMemo(() => {
    const currentPrevDeps = prevDeps.current;
    const isEquivalentToPrevious = !!currentPrevDeps && isEquivalentPredicate(currentPrevDeps);
    if (isEquivalentToPrevious) return currentPrevDeps;
    prevDeps.current = newDeps;
    return newDeps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, newDeps);
};

/**
 * useMemo-like hook where you can specify an additional predicate that should evaluate to true for the value to be reevaluated.
 * This can then be used to implement deep compare or similar variant of useMemo.
 *
 * @param valueFactory the factory function for the value to produce from the useMemo
 * @param isEquivalentPredicate the additional compare check on the deps that should evaluate to false to update the output value
 * @param deps list of dependencies similar to useMemo dependencies
 * @returns result of the factory function memoized
 */
export const usePredicateMemo = <TDeps extends readonly any[], TResult>(
  valueFactory: () => TResult,
  isEquivalentPredicate: (prevDeps: TDeps) => boolean,
  deps: TDeps
) => {
  const conditionalUpdateDeps = usePredicateDepsMemo(isEquivalentPredicate, deps);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(valueFactory, conditionalUpdateDeps);
};
