import React from 'react';
import styled from 'styled-components';
import { Button } from '../wrappedComponents';

const ConfirmModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export type ConfirmModalProps = {
  description: string;
  cancelText?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}
export const ConfirmModal = ({ description, cancelText, confirmText, onConfirm, onCancel }: ConfirmModalProps) => (
  <ConfirmModalContainer>
    <p>{description}</p>

    <ButtonRow>
      <Button variant="secondary" onClick={onCancel}>{cancelText || 'Cancel'}</Button>
      <Button variant="primary" onClick={onConfirm}>{confirmText || 'Confirm'}</Button>
    </ButtonRow>
  </ConfirmModalContainer>
);
